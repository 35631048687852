<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		size="xl"
		cancel-variant="outline-primary"
		button-size="md"
		:ok-title="this.isActionCrud === this.addData ? FormMSG(26, 'Add day to timesheet') : FormMSG(76, 'Update day to timesheet')"
		:cancel-title="FormMSG(27, 'Cancel')"
		ok-variant="primary"
		v-model="opened"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@ok.prevent="saveCurDay"
		@show="editData && isEditMode"
		@hidden="hideModalAddUpdate"
		:ok-disabled="disableFieldByStatus"
	>
		<template #modal-header="{ close }">
			<div class="d-flex flex-row w-100">
				<div class="d-flex flex-row w-100">
					<h5>{{ isActionCrud === addData ? FormMSG(25, 'Add work') : FormMSG(77, 'Update work') }}</h5>
				</div>
				<div class="d-flex flex-row w-100">
					<div v-if="isEditMode && editData !== null" class="d-flex w-80 align-items-center justify-content-end w-100">
						<span v-if="!isFilmSingle" class="border-encoded p-1"
							>{{ FormMSG(283, 'Encoded by: ') }} <b>{{ encoder(editData) }}</b>
						</span>
					</div>
					<div :class="editData && isEditMode ? 'w-20' : 'w-100'">
						<button class="btn-close" type="button" @click="hideModalAddUpdate()">×</button>
					</div>
				</div>
			</div>
		</template>
		<form-time-card
			:use-form-in-modal="true"
			use-form-for="web"
			:time-cards="TsDays"
			:time-card-to-edit="editData.tsDay"
			hide-alert
			:hide-status="hideStatusFormTimeCard"
			:multiple-selection-date="mutlipleSelectionDateFormTimeCard"
			hide-save-btn
			:hide-person-selector="false"
			:hide-department-selector="false"
			:is-my-time-card="false"
			:useFormInModal="useFormInModal"
			:screen-used-the-form="screenUsedTheForm"
			:user-id="currUserId"
			:submit-form="submitForm"
			:disable-user-select="update"
			:external-dates="externalDates"
			:use-view-planning="useViewPlanning"
			@form-time-card:udpate-date="getTsDays"
			@form-time-card:save-cur-day-success="handleFormTimeCardSubmitSuccess"
			@form-time-card:close-cur-day="handleFormTimeCardSubmitPresistent"
			@form-time-card:empty-list="handleFormTimeCardEmptyList"
			@senddataAuthToSubmitFormSelectMultiple="senddataAuthToSubmitFormSelectMultiple"
			:handleFormTimeCardSubmitSuccess="handleFormTimeCardSubmitSuccess"
			:isActionCrud="isActionCrud"
		/>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import StartEndTimeOverRange from '@/components/StartEndTimeOverRange';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { getUsers } from '../../cruds/users.crud';
import { Trash2 as Trash } from 'lucide-vue';
import { saveTsDaysForCrew } from '@/cruds/timesheet.crud';
import { ACTION_EFC_TIMESHEET } from '@/utils/utils';
import { classStatus } from '@/shared/global-status';
import FormTimeCard from '@/components/Work/FormTimeCard';
import { getProjectUserTsDays } from '@/cruds/timesheets-efc.crud.js';
import _ from 'lodash';

export default {
	name: 'TimeCardForCrewModal',

	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],

	props: {
		update: {
			type: Boolean,
			default: false,
			required: false
		},
		isOpen: {
			type: Boolean,
			default: false
		},
		showModal: {
			type: Function,
			required: false
		},
		hideModal: {
			type: Function,
			required: false
		},
		editData: {
			type: Object,
			default: null,
			required: false
		},
		isActionCrud: {
			type: String,
			required: false
		},
		addDataFunc: {
			type: Function,
			required: false
		},
		refreshDataItem: {
			type: Function,
			required: false
		},
		useFormInModal: {
			type: Boolean,
			required: false
		},
		screenUsedTheForm: {
			type: String,
			required: false
		},
		externalDates: {
			type: Array,
			default: () => [],
			required: false
		}
	},
	watch: {
		externalDates: {
			handler(newVal) {
				if (newVal.length > 0) {
					this.useViewPlanning = true;
				} else {
					this.useViewPlanning = false;
				}
			},
			immediate: true,
			deep: true
		},
		isOpen: {
			handler() {
				if (this.isOpen && this.isActionCrud === this.addData) {
					// this.firstTimeInitialisation();
					return this.isOpen;
				}
			}
		},
		update: {
			async handler(newVal) {
				if (newVal === true) {
					this.hideStatusFormTimeCard = false;
					this.mutlipleSelectionDateFormTimeCard = false;

					this.currUserId = parseInt(this.editData.tsDay.user.id, 10);
					await this.getTsDays({ currMonthStr: '' });
				}
			},
			immediate: true
		}
	},
	components: { StartEndTimeOverRange, Trash, FormTimeCard },

	data() {
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		return {
			contract: {
				defaultDayType: 0,
				hotelPerDiem: 0,
				lunchPerDiem: 0,
				lunchMinimum: 60,
				totMinPerDay: 480 // DEFAULT 8 HOUR WORK
			},
			department: '',
			curMonthStr: `${moment().format('YYYY')}-${moment().format('MM')}`,
			curDay: {
				id: 0,
				date: moment().format('YYYY-MM-DD') + 'T00:00:00Z',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				leftHomeAt: 'T07:00:00Z',
				returnedHomeAt: 'T19:00:00Z',
				totMin: 0,
				notSpecifiedLunch: false,
				lunchStrTime: 'T12:00:00Z',
				lunchEndTime: 'T13:00:00Z',
				lunch: false,
				hotel: false,
				useDinner: false,
				useAbroad: false,
				stageDayUsed: false,
				dayType: 0,
				validated: 0,
				kgCoTwo: 0,
				validatedStatus: 'Not Submited',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				statusClass: 'not-submitted',
				useMileageToSet: false,
				transportFromHomeToWork: false
			},
			DATA_INIT: this.curDay,
			valueCurDayFromDb: false,
			userList: [],
			allDepartements: [],
			selectedUser: [],
			currentMonth: '',
			addData,
			updateData,
			deleteData,
			dataToUpdate: {},
			classStatus,
			isValidate: false,

			// VALIDATION DEPARTMENT
			validationDepartments: [],
			TsDays: [],

			hideStatusFormTimeCard: true,
			mutlipleSelectionDateFormTimeCard: true,
			currUserId: null,
			submitForm: false,
			useViewPlanning: false,
			dataAuthToSubmitForm: []
		};
	},
	computed: {
		disableFieldByStatus() {
			if (this.editData.status !== 1 && this.editData.status !== 8 && this.editData.status !== 2) {
				return false;
			}

			return true;
		},
		opened: {
			get: function () {
				return this.isOpen;
			},
			set: function (val) {
				return val;
			}
		},
		optionsForDefaultDayType() {
			return this.FormMenu(1010);
		},
		isEditMode() {
			if (this.isActionCrud === this.updateData) {
				if (Object.keys(this.editData).length !== 0) {
					let data = {
						id: +this.editData.tsDay.id,
						date: moment(this.editData.tsDay.date).format('YYYY-MM-DD'),
						strTime: this.editData.tsDay.strTime,
						userId: this.editData.tsDay.user.id,
						endTime: this.editData.tsDay.endTime,
						leftHomeAt: this.editData.tsDay.leftHomeAt,
						returnedHomeAt: this.editData.tsDay.returnedHomeAt,
						totMin: 0,
						notSpecifiedLunch: this.editData.tsDay.notSpecifiedLunch,
						lunchStrTime: this.editData.tsDay.lunchStrTime,
						lunchEndTime: this.editData.tsDay.lunchEndTime,
						lunch: this.editData.tsDay.lunch,
						hotel: this.editData.tsDay.hotel,
						useDinner: this.editData.tsDay.useDinner,
						useAbroad: this.editData.tsDay.useAbroad,
						stageDayUsed: this.editData.tsDay.stageDayUsed,
						dayType: this.editData.tsDay.dayType,
						validated: this.editData.tsDay.validated,
						kgCoTwo: this.editData.tsDay.kgCoTwo,
						validatedStatus: this.getLabelStatus(this.editData.status),
						hours: this.editData.tsDay.hours,
						minutes: this.editData.tsDay.minutes,
						hoursOvertime: this.editData.tsDay.hoursOvertime,
						minutesOvertime: this.editData.tsDay.minutesOvertime,
						hoursOvertime1: this.editData.tsDay.hoursOvertime1,
						minutesOvertime1: this.editData.tsDay.minutesOvertime1,
						hoursOvertime2: this.editData.tsDay.hoursOvertime2,
						minutesOvertime2: this.editData.tsDay.minutesOvertime2,
						hoursTransportTimePaid: this.editData.tsDay.hoursTransportTimePaid,
						minutesTransportTimePaid: this.editData.tsDay.minutesTransportTimePaid,
						hoursNight: this.editData.tsDay.hoursNight,
						minutesNight: this.editData.minutesNight,
						hoursTooEarly: this.editData.tsDay.hoursTooEarly,
						minutesTooEarly: this.minutesTooEarly,
						salary: this.editData.tsDay.salary,
						hhmm: this.editData.tsDay.hours + this.editData.tsDay.minutes,
						hhmmOvt: `${
							+this.editData.tsDay.hoursOvertime < 10
								? '0' + this.editData.tsDay.hoursOvertime
								: this.editData.tsDay.hoursOvertime + ':' + this.editData.tsDay.minutesOvertime
						}`,
						hhmmOvt1: '',
						hhmmOvt2: '',
						hhmmTransport: `${
							+this.editData.tsDay.hoursTransportTimePaid < 10
								? '0' + this.editData.tsDay.hoursTransportTimePaid
								: this.editData.tsDay.hoursTransportTimePaid + ':' + this.editData.tsDay.minutesTransportTimePaid
						}`,
						hhmmNight: `${
							+this.editData.tsDay.hoursNight < 10
								? '0' + this.editData.tsDay.hoursNight
								: this.editData.tsDay.hoursNight + ':' + this.editData.tsDay.minutesNight
						}`,
						hhmmTooEarly: `${
							+this.editData.tsDay.hoursTooEarly < 10
								? '0' + this.editData.tsDay.hoursTooEarly
								: this.editData.tsDay.hoursTooEarly + ':' + this.editData.tsDay.minutesTooEarly
						}`,
						comment: '',
						statusClass: this.classStatus(this.editData.status),
						useMileageToSet: this.editData.tsDay.useMileageToSet,
						transportFromHomeToWork: this.editData.tsDay.transportFromHomeToWork
					};
					for (let i = 0; i < this.allDepartements.length; i++) {
						if (this.editData.departmentName.toUpperCase() === this.allDepartements[i].message.toUpperCase()) {
							this.department = this.allDepartements[i].value;
						}
					}
					this.dataToUpdate = data;
					this.curDay = data;
					return this.curDay;
				}
			}
		}
	},
	async created() {
		this.$nextTick(async () => {
			//moment.locale(getLangISOByIndex(store.appLanguage()).two);
			this.currentMonth = moment().format('MMMM');
			if (this.isActionCrud === this.addData) {
				// this.firstTimeInitialisation();
			}
			await this.reloadData();
		});
	},
	methods: {
		encoder(data) {
			return `${data.tsDay.encoder.firstName} ${data.tsDay.encoder.name}`;
		},
		async handleFormTimeCardSubmitSuccess(e) {
			this.hideModal();
			this.resetData();
			this.$emit('add:Submitted', e);
		},
		handleFormTimeCardSubmitPresistent() {
			this.submitForm = false;
		},
		async handleFormTimeCardEmptyList() {
			this.submitForm = false;
			this.hideModal();
			this.resetData();
		},
		async getTsDays({ currMonthStr = '' }) {
			if (this.editData.tsDay) {
				let StartDate = '';
				if (currMonthStr === '') {
					StartDate = moment(new Date(this.editData.tsDay.strTime)).format('YYYY-MM');
				} else {
					StartDate = currMonthStr;
				}

				StartDate += '-01T00:00:00+00:00';

				this.TsDays = [];
				const result = await getProjectUserTsDays({
					personId: parseInt(this.editData.tsDay.user.id, 10),
					startDate: StartDate
				});

				result.map((res) => {
					let data = this.updateTZdataEach(res.tsDay);
					this.TsDays.push({ ...res.tsDay, ...data });
				});
			}
		},
		validatedText(validated) {
			// returns the label text corresponding to the validated value from mixin function
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'info';
			}
			return retval;
		},
		updateTZdataEach(timeData) {
			if (_.isNil(timeData)) return;
			// setting row color
			////console.log("timeData.endTime:",timeData.endTime);
			timeData.validatedStatus = this.validatedText(timeData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			timeData._rowVariant = ''; //this.validatedColor(timeData.validated);
			timeData.validatedClass = this.validatedColor(timeData.validated);
			timeData.statusClass = classStatus(timeData.validated);
			//console.log("timeData.strTime before:",timeData.strTime);
			// format to local time
			timeData.strTime = moment(timeData.strTime).format();
			timeData.endTime = moment(timeData.endTime).format();
			timeData.lunchStrTime = moment(timeData.lunchStrTime).format();
			timeData.lunchEndTime = moment(timeData.lunchEndTime).format();
			timeData.leftHomeAt = moment(timeData.leftHomeAt).format();
			timeData.returnedHomeAt = moment(timeData.returnedHomeAt).format();
			//console.log("timeData.strTime after:",timeData.strTime);
			timeData.hhmm = timeData.hours + ':' + timeData.minutes;
			timeData.hhmmOvt = timeData.hoursOvertime + ':' + timeData.minutesOvertime;
			timeData.hhmmOvt1 = timeData.hoursOvertime1 + ':' + timeData.minutesOvertime1;
			timeData.hhmmOvt2 = timeData.hoursOvertime2 + ':' + timeData.minutesOvertime2;
			timeData.hhmmTransport = timeData.hoursTransportTimePaid + ':' + timeData.minutesTransportTimePaid;
			timeData.hhmmNight = timeData.hoursNight + ':' + timeData.minutesNight;
			timeData.hhmmTooEarly = timeData.hoursTooEarly + ':' + timeData.minutesTooEarly;

			timeData.hotelPerDiem = timeData.contract.hotelPerDiem;
			timeData.lunchPerDiem = timeData.contract.lunchPerDiem;
			timeData.dinnerPerDiem = timeData.contract.dinnerPerDiem;
			timeData.abroadPerDiem = timeData.contract.abroadPerDiem;
			//console.log("this.contract:",this.contract)

			timeData.isSelected = false;
			timeData.showDetails = false;
		},
		async reloadData() {
			await this.initModalUser();
		},
		async initModalUser() {
			await getUsers({ myProject: true }).then((users) => {
				users.forEach((user) => {
					user.value = user.id;
					user.message = user.fullName;
				});
				this.userList = [
					...users.sort((a, b) => {
						if (a.fullName.toUpperCase() < b.fullName.toUpperCase()) return -1;
						if (a.fullName.toUpperCase() > b.fullName.toUpperCase()) return 1;
						return 0;
					})
				].map((item) => ({ value: item.id, message: item.fullName, userId: item.id, departementId: item.department }));
			});
		},
		async addUpdateItemData() {
			// add and update item
			const curDayDate = this.curDay.date;
			this.curDay.date = this.curDay.date.substring(0, 10);

			let StrTime = this.curDay.strTime;
			let EndTime = this.curDay.endTime;
			if (EndTime < StrTime) {
				alert(this.FormMSG(1111, 'End time is before start time!'));
				return;
			}
			let LeftHomeAt = this.curDay.leftHomeAt;
			let ReturnedHomeAt = this.curDay.returnedHomeAt;

			if (this.curDay.transportFromHomeToWork == false) {
				LeftHomeAt = StrTime;
				ReturnedHomeAt = EndTime;
			}

			if (this.curDay.returnedHomeAt < this.curDay.endTime) {
				this.curDay.returnedHomeAt = this.curDay.endTime;
			}

			if (this.curDay.leftHomeAt > this.curDay.strTime) {
				this.curDay.leftHomeAt = this.curDay.strTime;
			}

			if (ReturnedHomeAt < LeftHomeAt) {
				alert(this.FormMSG(1112, 'Returned home time is before left home time!'));
				return;
			}
			let LunchStrTime = this.curDay.lunchStrTime;
			let LunchEndTime = this.curDay.lunchEndTime;

			if (LunchStrTime === LunchEndTime || this.curDay.notSpecifiedLunch) {
				LunchStrTime = StrTime;
				LunchEndTime = StrTime;
			}

			if (LunchEndTime < LunchStrTime) {
				alert(this.FormMSG(1113, 'Lunch end time is before lunch start time!'));
				return;
			}

			if (this.showStageDay() && this.curDay.stageDayUsed) {
				StrTime = moment(this.curDay.date).add(8, 'hours').format();
				EndTime = null;
			} else {
				this.curDay.useStageDay = false;
			}

			let Lunch = false;
			if (this.curDay.lunch == true) {
				Lunch = true;
			}

			let Hotel = false;
			if (this.curDay.hotel == true) {
				Hotel = true;
			}

			let UseDinner = false;
			if (this.curDay.useDinner == true) {
				UseDinner = true;
			}

			let UseAbroad = false;
			if (this.curDay.useAbroad == true) {
				UseAbroad = true;
			}

			let UseMileageToSet = false;
			if (this.curDay.useMileageToSet == true) {
				UseMileageToSet = true;
			}

			const DayType = this.curDay.dayType;
			this.disableSaveButton = true;
			const NotSpecifiedLunch = this.curDay.notSpecifiedLunch;
			const TransportFromHomeToWork = this.curDay.transportFromHomeToWork;
			let UserId = [];
			if (this.isActionCrud === this.updateData) {
				UserId.push(+this.curDay.userId);
			} else {
				this.selectedUser.map((usr) => {
					UserId.push(parseInt(usr.value, 10));
				});
			}
			await saveTsDaysForCrew(
				curDayDate,
				StrTime,
				EndTime,
				LunchStrTime,
				LunchEndTime,
				Lunch,
				Hotel,
				UseDinner,
				UseAbroad,
				DayType,
				LeftHomeAt,
				ReturnedHomeAt,
				NotSpecifiedLunch,
				UseMileageToSet,
				TransportFromHomeToWork,
				UserId
			)
				.then(async (result) => {
					await this.reloadData();
					if (this.isActionCrud === this.updateData) {
						this.refreshDataItem(this.dataToUpdate);
					}
					this.disableSaveButton = false;
					this.createToastForMobile(
						this.FormMSG(162, 'Success'),
						this.isActionCrud === this.addData ? this.FormMSG(39, 'timesheet added to Timesheet!') : this.FormMSG(40, 'timesheet updated!'),
						''
					);
				})
				.catch((error) => {
					this.disableSaveButton = false;
					if (error.message.includes('no valid contract on this day')) {
						var errStr = this.FormMSG(251, 'There is no valid contract for this day');
						alert(errStr);
					} else {
						this.erreur = error;
						alert("Internal error (check network and if user's contract is valid)");
					}
				});
		},
		senddataAuthToSubmitFormSelectMultiple(arrays) {
			const { dates, persons, department, selectTypeMulti, useViewPlanning } = arrays;
			this.dataAuthToSubmitForm = {
				dates: dates,
				persons: persons !== null ? persons : '',
				department: department !== null ? department : '',
				selectTypeMulti: selectTypeMulti,
				useViewPlanning: useViewPlanning
			};
		},
		saveCurDay() {
			if (
				(this.isActionCrud === this.addData && this.dataAuthToSubmitForm.dates.length !== 0 && this.dataAuthToSubmitForm.persons.length !== 0) ||
				(this.isActionCrud === this.addData && this.dataAuthToSubmitForm.dates.length !== 0 && this.dataAuthToSubmitForm.department.length !== 0)
			) {
				this.submitForm = true;
			}
			if (this.dataAuthToSubmitForm.dates.length !== 0 && this.dataAuthToSubmitForm.useViewPlanning === true) {
				this.submitForm = true;
			}
			if (this.isActionCrud === this.updateData) {
				this.submitForm = true;
			}
		},
		hideModalAddUpdate() {
			// this.hideModal();
			this.resetData();
			this.$emit('closeModal');
			// this.$emit('resetDataAfterAddUpdate');
		},
		resetData() {
			const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
			this.contract = {
				defaultDayType: 0,
				hotelPerDiem: 0,
				lunchPerDiem: 0,
				lunchMinimum: 60,
				totMinPerDay: 480
			};

			this.department = '';
			this.curDay = {
				id: 0,
				date: moment().format('YYYY-MM-DD') + 'T00:00:00Z',
				strTime: 'T08:00:00Z',
				userId: '',
				endTime: 'T18:00:00Z',
				leftHomeAt: 'T07:00:00Z',
				returnedHomeAt: 'T19:00:00Z',
				totMin: 0,
				notSpecifiedLunch: false,
				lunchStrTime: 'T12:00:00Z',
				lunchEndTime: 'T13:00:00Z',
				lunch: false,
				hotel: false,
				useDinner: false,
				useAbroad: false,
				stageDayUsed: false,
				dayType: 0,
				validated: 0,
				kgCoTwo: 0,
				validatedStatus: 'Not Submited',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				statusClass: 'not-submitted',
				useMileageToSet: false,
				transportFromHomeToWork: false
			};
			this.DATA_INIT = this.curDay;
			this.valueCurDayFromDb = false;
			this.selectedUser = [];
			this.currentMonth = '';
			this.addData = addData;
			this.updateData = updateData;
			this.deleteData = deleteData;
			this.dataToUpdate = {};
			this.classStatus = classStatus;
			this.isValidate = false;

			this.validationDepartments = [];
			this.TsDays = [];

			this.hideStatusFormTimeCard = true;
			this.mutlipleSelectionDateFormTimeCard = true;
			this.currUserId = null;
			this.submitForm = false;
			this.useViewPlanning = false;
			// this.firstTimeInitialisation();
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		}
	}
};
</script>

<style scoped>
.btn-close {
	background-color: transparent;
	border: 0;
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
}
.border-encoded {
	border: 2px solid #cad0d7;
	border-radius: 5px;
}
</style>
