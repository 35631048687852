var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-layout form px-0" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "between" } },
        [
          _c(
            "b-col",
            { staticClass: "pb-2 ml-1", attrs: { cols: "5" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mb-0" },
                [
                  _vm.$screen.width >= 992
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.focus",
                                modifiers: { focus: true },
                              },
                            ],
                            attrs: {
                              type: "text",
                              id: "filterInput",
                              placeholder: this.FormMSG(37, "Type to Search"),
                            },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "filter",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            { staticClass: "cursor-pointer" },
                            [
                              _c(
                                "b-input-group-text",
                                { staticClass: "btn-search" },
                                [
                                  _vm.filter.length === 0
                                    ? _c("Search", {
                                        staticClass: "icon",
                                        attrs: {
                                          color: "#FFFFFF",
                                          size: 16,
                                          "stroke-width": 2.5,
                                        },
                                      })
                                    : _c("X", {
                                        staticClass: "icon",
                                        attrs: {
                                          color: "#FFFFFF",
                                          size: 16,
                                          "stroke-width": 2.5,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.filter = ""
                                          },
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: "filterInput",
                                    placement: "top",
                                    triggers: "focus",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        this.FormMSG(
                                          12,
                                          "Type 'Enter' to launch search"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-col", { staticClass: "pb-3 ml-1", attrs: { cols: "3" } }, [
            _c(
              "div",
              { staticClass: "mr-3 text-color-australien w-100 pj-cb" },
              [
                _c("div", { staticClass: "fs-14" }, [
                  _vm.totalAllPageRecords > 0
                    ? _c("b", [
                        _vm._v(
                          _vm._s(_vm.startTotalPageNow()) +
                            " - " +
                            _vm._s(_vm.totalPageNow()) +
                            " " +
                            _vm._s(this.FormMSG(25, "of")) +
                            " " +
                            _vm._s(_vm.totalAllPageRecords) +
                            "\n\t\t\t\t\t\t" +
                            _vm._s(this.FormMSG(26, "records"))
                        ),
                      ])
                    : _c("b", [_vm._v(_vm._s(this.FormMSG(33, "No records")))]),
                ]),
              ]
            ),
          ]),
          _c("b-col", { staticClass: "pb-3 ml-1", attrs: { cols: "3" } }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "div",
                { staticClass: "d-flex w-100" },
                [
                  _c(
                    "div",
                    { staticClass: "mr-3 w-100-px" },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.pages,
                          label: "text",
                          reduce: (option) => option.value,
                          clearable: false,
                        },
                        on: {
                          "option:selected": function ($event) {
                            return _vm.setPagesFunc($event)
                          },
                        },
                        model: {
                          value: _vm.setPage,
                          callback: function ($$v) {
                            _vm.setPage = $$v
                          },
                          expression: "setPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-flex align-items-center mr-2 px-3",
                      attrs: {
                        variant: "primary",
                        size: "sm",
                        disabled:
                          _vm.startTotalPageNow() === 0 ||
                          _vm.disableButtonPrev,
                      },
                      on: { click: _vm.handleClickPrevious },
                    },
                    [
                      _c(_vm.getLucideIcon(_vm.ICONS.SKIP_BACK.name), {
                        tag: "component",
                        attrs: { color: _vm.ICONS.SKIP_BACK.color, size: 16 },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "ml-2",
                          staticStyle: { "margin-top": "1px" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(23, "Prev")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-flex align-items-center px-3",
                      attrs: {
                        variant: "primary",
                        size: "sm",
                        disabled:
                          _vm.totalPageNow() === _vm.totalAllPageRecords ||
                          _vm.disableButtonNext,
                      },
                      on: { click: _vm.handleClickNext },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mr-2",
                          staticStyle: { "margin-top": "1px" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(24, "Next")))]
                      ),
                      _c(_vm.getLucideIcon(_vm.ICONS.SKIP_FORWARD.name), {
                        tag: "component",
                        attrs: {
                          color: _vm.ICONS.SKIP_FORWARD.color,
                          size: 16,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "pl-4 pt-2", attrs: { md: "6" } }, [
            _c(
              "p",
              {
                staticClass: "fw-400",
                staticStyle: {
                  "font-size": "13px",
                  color: "rgba(6, 38, 62, 0.65)",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      this.FormMSG(
                        8,
                        "* Click on a row to view more informations or edit"
                      )
                    ) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { attrs: { "align-h": "between" } },
        [
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "pj-cb pb-1",
                      attrs: { size: "lg" },
                      on: {
                        change: function ($event) {
                          return _vm.showSalaryAction($event)
                        },
                      },
                      model: {
                        value: _vm.isShowSalaryDetail,
                        callback: function ($$v) {
                          _vm.isShowSalaryDetail = $$v
                        },
                        expression: "isShowSalaryDetail",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(this.FormMSG(4, "Show salary details")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSubmittedStatus,
                  expression: "isSubmittedStatus",
                },
              ],
              attrs: { md: "3" },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.notSumbmittedItem,
                      expression: "notSumbmittedItem",
                    },
                  ],
                },
                [
                  _c(
                    "b-form-group",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "pj-cb",
                          attrs: {
                            size: "lg",
                            value: true,
                            "unchecked-value": false,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.selectAllNotSubmitted($event)
                            },
                          },
                          model: {
                            value: _vm.notSumbmittedItem,
                            callback: function ($$v) {
                              _vm.notSumbmittedItem = $$v
                            },
                            expression: "notSumbmittedItem",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "margin-top": "1px" } }, [
                            _vm._v(
                              _vm._s(
                                this.FormMSG(27, "Unselect all not submitted")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.notSumbmittedItem,
                      expression: "!notSumbmittedItem",
                    },
                  ],
                },
                [
                  _c(
                    "b-form-group",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "pj-cb",
                          attrs: {
                            size: "lg",
                            value: true,
                            "unchecked-value": false,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.selectAllNotSubmitted($event)
                            },
                          },
                          model: {
                            value: _vm.notSumbmittedItem,
                            callback: function ($$v) {
                              _vm.notSumbmittedItem = $$v
                            },
                            expression: "notSumbmittedItem",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "margin-top": "1px" } }, [
                            _vm._v(
                              _vm._s(
                                this.FormMSG(10, "Select all not submitted")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "float-right pb-2 mt-3", attrs: { md: "7" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn btn-primary pull-right block-sm",
                  attrs: { disabled: _vm.itemIsNotSubmitted.length === 0 },
                  on: { click: _vm.sendForValidation },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(this.FormMSG(2, "Send for validation")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row mt-3 px-3" },
        [
          _c("b-table", {
            staticClass: "w-100",
            staticStyle: { "text-align": "center" },
            attrs: {
              filter: _vm.filter,
              responsive: "sm",
              items: _vm.dataTable,
              fields: _vm.tsfields,
              "empty-text": this.FormMSG(7, "No timesheet found"),
              bordered: "",
              "sticky-header": "700px",
              "head-variant": "dark",
              "show-empty": "",
            },
            on: { "row-clicked": _vm.rowClicked },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(select)",
                  fn: function (data) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: data.item.status === 0,
                              expression: "data.item.status === 0",
                            },
                          ],
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "pj-cb pb-1",
                            attrs: {
                              id: _vm.generateSecureId(
                                `timesheets-efc-selected-${
                                  data.item.id + Math.random()
                                }`
                              ),
                              name: _vm.generateSecureId(
                                `timesheetsEfcSelected${data.item.id}`
                              ),
                              size: "lg",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeTimesheetsEfcSelected(
                                  $event,
                                  data.item
                                )
                              },
                            },
                            model: {
                              value: data.item.isSelected,
                              callback: function ($$v) {
                                _vm.$set(data.item, "isSelected", $$v)
                              },
                              expression: "data.item.isSelected",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(crew_member)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(data.item.crew_member) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(status)",
                  fn: function (data) {
                    return [
                      _c("div", { staticClass: "wrap-status" }, [
                        _c(
                          "div",
                          {
                            class: `status ${_vm.rendColor(data.item.status)}`,
                            staticStyle: { "font-size": "0.7rem" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(data.item.validatedText) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(encoder)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(_vm._s(_vm.encodedBy(data.item.tsDay))),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(worked)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.setWorked(
                              data.item.tsDay.hours,
                              data.item.tsDay.minutes
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(dayType)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.getFieldValueByShowGridDetails(
                                _vm.showGridDetails,
                                data.item.tsDay.dayType,
                                data.item.tsDay.salaryBase
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                      _vm.isShowSalaryDetail
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.displayCurrency(data.item.tsDay.salaryBase)
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(ovt)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-row justify-content-center",
                          staticStyle: { gap: "4px" },
                        },
                        [
                          _vm.setTotalOvt(
                            [
                              data.item.tsDay.hoursOvertime,
                              data.item.tsDay.hoursOvertime1,
                              data.item.tsDay.hoursOvertime2,
                            ],
                            [
                              data.item.tsDay.minutesOvertime,
                              data.item.tsDay.minutesOvertime1,
                              data.item.tsDay.minutesOvertime2,
                            ]
                          ) !== "00:00"
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.left.html",
                                      value: _vm.tooltipContentOvertime(
                                        data.item.tsDay
                                      ),
                                      expression:
                                        "tooltipContentOvertime(data.item.tsDay)",
                                      modifiers: {
                                        hover: true,
                                        left: true,
                                        html: true,
                                      },
                                    },
                                  ],
                                  staticClass:
                                    "btn-transparent text-color-rhapsody-in-blue",
                                },
                                [
                                  _c(_vm.getLucideIcon("Info"), {
                                    tag: "component",
                                    attrs: { color: "#225CBD", size: 16 },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.setTotalOvt(
                                    [
                                      data.item.tsDay.hoursOvertime,
                                      data.item.tsDay.hoursOvertime1,
                                      data.item.tsDay.hoursOvertime2,
                                    ],
                                    [
                                      data.item.tsDay.minutesOvertime,
                                      data.item.tsDay.minutesOvertime1,
                                      data.item.tsDay.minutesOvertime2,
                                    ]
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                      _vm.isShowSalaryDetail
                        ? _c("div", [
                            _c("div", [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.setSommeOvt(
                                      data.item.tsDay.salaryOvertime,
                                      data.item.tsDay.salaryOvertime1,
                                      data.item.tsDay.salaryOvertime2
                                    )
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(lunch)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              !data.item.tsDay.notSpecifiedLunch
                                ? `${_vm.convertTime(
                                    data.item.tsDay.lunchStrTime
                                  )} - ${_vm.convertTime(
                                    data.item.tsDay.lunchEndTime
                                  )}`
                                : "-"
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(night)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            data.item.tsDay.hoursNight
                              .toString()
                              .padStart("2", "0")
                          ) +
                            ":" +
                            _vm._s(data.item.tsDay.minutesNight)
                        ),
                      ]),
                      _vm.isShowSalaryDetail
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.displayCurrency(
                                  data.item.tsDay.salaryHourNight
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(anticipated)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            data.item.tsDay.hoursTooEarly
                              .toString()
                              .padStart("2", "0")
                          ) +
                            ":" +
                            _vm._s(data.item.tsDay.minutesTooEarly)
                        ),
                      ]),
                      _vm.isShowSalaryDetail
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.displayCurrency(
                                  data.item.tsDay.salaryHourTooEarly
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                _vm.showLunchPerDiem() ||
                _vm.showHotelPerDiem() ||
                _vm.showDinnerPerDiem() ||
                _vm.showAbroadPerDiem()
                  ? {
                      key: "cell(totPerDiem)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-content-center",
                              staticStyle: { gap: "4px" },
                            },
                            [
                              data.item.tsDay.totPerDiem > 0
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName:
                                            "v-b-tooltip.hover.left.html",
                                          value: _vm.tooltipContentPerIdem(
                                            data.item.tsDay
                                          ),
                                          expression:
                                            "tooltipContentPerIdem(data.item.tsDay)",
                                          modifiers: {
                                            hover: true,
                                            left: true,
                                            html: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "btn-transparent text-color-rhapsody-in-blue",
                                    },
                                    [
                                      _c(_vm.getLucideIcon("Info"), {
                                        tag: "component",
                                        attrs: { color: "#225CBD", size: 16 },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.displayCurrency(
                                      data.item.tsDay.totPerDiem
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    }
                  : null,
                {
                  key: "cell(dayRate)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(_vm._s(data.item.tsDay.dayRate) + "%"),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(transport)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            data.item.tsDay.hoursTransportTimePaid
                              .toString()
                              .padStart("2", "0")
                          ) +
                            ":" +
                            _vm._s(data.item.tsDay.minutesTransportTimePaid)
                        ),
                      ]),
                      _vm.isShowSalaryDetail
                        ? _c("div", [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.displayCurrency(
                                    data.item.tsDay.salaryTransport
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(co2)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.displayKgCo2(data.item.tsDay.kgCoTwo))
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(allowances)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-row justify-content-center",
                          staticStyle: { gap: "4px" },
                        },
                        [
                          data.item.tsDay.totAllowances > 0
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.left.html",
                                      value: _vm.tooltipContentAllowance(
                                        data.item.tsDay
                                      ),
                                      expression:
                                        "tooltipContentAllowance(data.item.tsDay)",
                                      modifiers: {
                                        hover: true,
                                        left: true,
                                        html: true,
                                      },
                                    },
                                  ],
                                  staticClass:
                                    "btn-transparent text-color-rhapsody-in-blue",
                                },
                                [
                                  _c(_vm.getLucideIcon("Info"), {
                                    tag: "component",
                                    attrs: { color: "#225CBD", size: 16 },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.displayCurrency(
                                  data.item.tsDay.totAllowances
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(salary)",
                  fn: function (data) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.displayCurrency(data.item.tsDay.salary))
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(remove)",
                  fn: function (data) {
                    return [
                      data.item.status === 0 ||
                      data.item.status === 4 ||
                      data.item.status === 16
                        ? _c(
                            "div",
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.TRASH.color,
                                  size: 20,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.deleteActionTables(data.item)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c("time-card-for-crew-modal", {
        attrs: {
          isActionCrud: _vm.isActionCrud,
          update: _vm.update,
          editData: _vm.editData,
          isOpen: _vm.Open,
          showModal: _vm.showModal,
          hideModal: _vm.hideModal,
          screenUsedTheForm: `entry-for-crew`,
          useFormInModal: _vm.useFormInModal,
        },
        on: {
          "add:Submitted": _vm.handleRefreshData,
          closeModal: _vm.closeModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }