<template>
	<div class="container-layout form px-0">
		<b-row align-h="between">
			<b-col cols="5" class="pb-2 ml-1">
				<b-form-group class="mb-0">
					<b-input-group v-if="$screen.width >= 992">
						<b-form-input v-model.trim="filter" type="text" id="filterInput" :placeholder="this.FormMSG(37, 'Type to Search')" v-b-tooltip.focus />
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
								<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
							</b-input-group-text>
							<b-tooltip target="filterInput" placement="top" triggers="focus">
								{{ this.FormMSG(12, "Type 'Enter' to launch search") }}
							</b-tooltip>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col cols="3" class="pb-3 ml-1">
				<div class="mr-3 text-color-australien w-100 pj-cb">
					<div class="fs-14">
						<b v-if="totalAllPageRecords > 0"
							>{{ startTotalPageNow() }} - {{ totalPageNow() }} {{ this.FormMSG(25, 'of') }} {{ totalAllPageRecords }}
							{{ this.FormMSG(26, 'records') }}</b
						>
						<b v-else>{{ this.FormMSG(33, 'No records') }}</b>
					</div>
				</div>
			</b-col>
			<b-col cols="3" class="pb-3 ml-1">
				<div class="float-right">
					<div class="d-flex w-100">
						<div class="mr-3 w-100-px">
							<v-select
								v-model="setPage"
								:options="pages"
								@option:selected="setPagesFunc($event)"
								label="text"
								:reduce="(option) => option.value"
								:clearable="false"
							/>
						</div>
						<b-button
							variant="primary"
							size="sm"
							class="d-flex align-items-center mr-2 px-3"
							:disabled="startTotalPageNow() === 0 || disableButtonPrev"
							@click="handleClickPrevious"
						>
							<component :is="getLucideIcon(ICONS.SKIP_BACK.name)" :color="ICONS.SKIP_BACK.color" :size="16" />
							<div class="ml-2" style="margin-top: 1px">{{ FormMSG(23, 'Prev') }}</div>
						</b-button>
						<b-button
							class="d-flex align-items-center px-3"
							variant="primary"
							size="sm"
							:disabled="totalPageNow() === totalAllPageRecords || disableButtonNext"
							@click="handleClickNext"
						>
							<div class="mr-2" style="margin-top: 1px">{{ FormMSG(24, 'Next') }}</div>
							<component :is="getLucideIcon(ICONS.SKIP_FORWARD.name)" :color="ICONS.SKIP_FORWARD.color" :size="16" />
						</b-button>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="6" class="pl-4 pt-2">
				<p class="fw-400" style="font-size: 13px; color: rgba(6, 38, 62, 0.65)">
					{{ this.FormMSG(8, '* Click on a row to view more informations or edit') }}
				</p>
			</b-col>
		</b-row>
		<b-row align-h="between">
			<b-col md="2">
				<b-form-group class="mt-3">
					<b-form-checkbox v-model="isShowSalaryDetail" @change="showSalaryAction($event)" size="lg" class="pj-cb pb-1">
						{{ this.FormMSG(4, 'Show salary details') }}
					</b-form-checkbox>
				</b-form-group>
			</b-col>
			<b-col md="3" v-show="isSubmittedStatus">
				<div v-show="notSumbmittedItem">
					<b-form-group class="mt-3">
						<b-form-checkbox
							size="lg"
							v-model="notSumbmittedItem"
							class="pj-cb"
							:value="true"
							:unchecked-value="false"
							@change="selectAllNotSubmitted($event)"
						>
							<div style="margin-top: 1px">{{ this.FormMSG(27, 'Unselect all not submitted') }}</div>
						</b-form-checkbox>
					</b-form-group>
				</div>
				<div v-show="!notSumbmittedItem">
					<b-form-group class="mt-3">
						<b-form-checkbox
							size="lg"
							v-model="notSumbmittedItem"
							class="pj-cb"
							:value="true"
							:unchecked-value="false"
							@change="selectAllNotSubmitted($event)"
						>
							<div style="margin-top: 1px">{{ this.FormMSG(10, 'Select all not submitted') }}</div>
						</b-form-checkbox>
					</b-form-group>
				</div>
			</b-col>
			<b-col md="7" class="float-right pb-2 mt-3">
				<b-button :disabled="itemIsNotSubmitted.length === 0" @click="sendForValidation" class="btn btn-primary pull-right block-sm">
					{{ this.FormMSG(2, 'Send for validation') }}
				</b-button>
			</b-col>
		</b-row>
		<div class="row mt-3 px-3">
			<b-table
				:filter="filter"
				responsive="sm"
				class="w-100"
				:items="dataTable"
				style="text-align: center"
				:fields="tsfields"
				:empty-text="this.FormMSG(7, 'No timesheet found')"
				bordered
				sticky-header="700px"
				head-variant="dark"
				show-empty
				@row-clicked="rowClicked"
			>
				<template #cell(select)="data">
					<span v-show="data.item.status === 0">
						<b-form-checkbox
							:id="generateSecureId(`timesheets-efc-selected-${data.item.id + Math.random()}`)"
							:name="generateSecureId(`timesheetsEfcSelected${data.item.id}`)"
							v-model="data.item.isSelected"
							size="lg"
							class="pj-cb pb-1"
							@change="handleChangeTimesheetsEfcSelected($event, data.item)"
						/>
					</span>
				</template>
				<template #cell(crew_member)="data">
					<div>
						{{ data.item.crew_member }}
					</div>
				</template>
				<template #cell(status)="data">
					<div class="wrap-status">
						<div :class="`status ${rendColor(data.item.status)}`" style="font-size: 0.7rem">
							{{ data.item.validatedText }}
						</div>
					</div>
				</template>
				<template #cell(encoder)="data">
					<div>{{ encodedBy(data.item.tsDay) }}</div>
				</template>
				<template #cell(worked)="data">
					<div>{{ setWorked(data.item.tsDay.hours, data.item.tsDay.minutes) }}</div>
				</template>
				<template #cell(dayType)="data">
					<div>
						{{ getFieldValueByShowGridDetails(showGridDetails, data.item.tsDay.dayType, data.item.tsDay.salaryBase) }}
					</div>
					<div v-if="isShowSalaryDetail">{{ displayCurrency(data.item.tsDay.salaryBase) }}</div>
				</template>
				<template #cell(ovt)="data">
					<div class="d-flex flex-row justify-content-center" style="gap: 4px">
						<button
							v-if="
								setTotalOvt(
									[data.item.tsDay.hoursOvertime, data.item.tsDay.hoursOvertime1, data.item.tsDay.hoursOvertime2],
									[data.item.tsDay.minutesOvertime, data.item.tsDay.minutesOvertime1, data.item.tsDay.minutesOvertime2]
								) !== '00:00'
							"
							class="btn-transparent text-color-rhapsody-in-blue"
							v-b-tooltip.hover.left.html="tooltipContentOvertime(data.item.tsDay)"
						>
							<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
						</button>
						<div>
							{{
								setTotalOvt(
									[data.item.tsDay.hoursOvertime, data.item.tsDay.hoursOvertime1, data.item.tsDay.hoursOvertime2],
									[data.item.tsDay.minutesOvertime, data.item.tsDay.minutesOvertime1, data.item.tsDay.minutesOvertime2]
								)
							}}
						</div>
					</div>
					<div v-if="isShowSalaryDetail">
						<div>
							{{ setSommeOvt(data.item.tsDay.salaryOvertime, data.item.tsDay.salaryOvertime1, data.item.tsDay.salaryOvertime2) }}
						</div>
					</div>
				</template>
				<template #cell(lunch)="data">
					<div>
						{{
							!data.item.tsDay.notSpecifiedLunch
								? `${convertTime(data.item.tsDay.lunchStrTime)} - ${convertTime(data.item.tsDay.lunchEndTime)}`
								: '-'
						}}
					</div>
				</template>
				<template #cell(night)="data">
					<div>{{ data.item.tsDay.hoursNight.toString().padStart('2', '0') }}:{{ data.item.tsDay.minutesNight }}</div>
					<div v-if="isShowSalaryDetail">{{ displayCurrency(data.item.tsDay.salaryHourNight) }}</div>
				</template>
				<template #cell(anticipated)="data">
					<div>{{ data.item.tsDay.hoursTooEarly.toString().padStart('2', '0') }}:{{ data.item.tsDay.minutesTooEarly }}</div>
					<div v-if="isShowSalaryDetail">{{ displayCurrency(data.item.tsDay.salaryHourTooEarly) }}</div>
				</template>
				<template #cell(totPerDiem)="data" v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()">
					<div class="d-flex flex-row justify-content-center" style="gap: 4px">
						<button
							v-if="data.item.tsDay.totPerDiem > 0"
							class="btn-transparent text-color-rhapsody-in-blue"
							v-b-tooltip.hover.left.html="tooltipContentPerIdem(data.item.tsDay)"
						>
							<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
						</button>
						<div>{{ displayCurrency(data.item.tsDay.totPerDiem) }}</div>
					</div>
				</template>
				<template #cell(dayRate)="data">
					<div>{{ data.item.tsDay.dayRate }}%</div>
				</template>

				<template #cell(transport)="data">
					<div>{{ data.item.tsDay.hoursTransportTimePaid.toString().padStart('2', '0') }}:{{ data.item.tsDay.minutesTransportTimePaid }}</div>
					<div v-if="isShowSalaryDetail">
						<div>{{ displayCurrency(data.item.tsDay.salaryTransport) }}</div>
					</div>
				</template>
				<template #cell(co2)="data">
					<div>{{ displayKgCo2(data.item.tsDay.kgCoTwo) }}</div>
				</template>
				<template #cell(allowances)="data">
					<div class="d-flex flex-row justify-content-center" style="gap: 4px">
						<button
							v-if="data.item.tsDay.totAllowances > 0"
							class="btn-transparent text-color-rhapsody-in-blue"
							v-b-tooltip.hover.left.html="tooltipContentAllowance(data.item.tsDay)"
						>
							<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
						</button>
						<div>{{ displayCurrency(data.item.tsDay.totAllowances) }}</div>
					</div>
				</template>
				<template #cell(salary)="data">
					<div>{{ displayCurrency(data.item.tsDay.salary) }}</div>
				</template>
				<template #cell(remove)="data">
					<div v-if="data.item.status === 0 || data.item.status === 4 || data.item.status === 16">
						<component
							:is="getLucideIcon(ICONS.TRASH.name)"
							:color="ICONS.TRASH.color"
							:size="20"
							@click.stop.prevent="deleteActionTables(data.item)"
						/>
					</div>
				</template>
			</b-table>
		</div>
		<time-card-for-crew-modal
			@add:Submitted="handleRefreshData"
			:isActionCrud="isActionCrud"
			:update="update"
			:editData="editData"
			:isOpen="Open"
			:showModal="showModal"
			:hideModal="hideModal"
			@closeModal="closeModal"
			:screenUsedTheForm="`entry-for-crew`"
			:useFormInModal="useFormInModal"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import TimeCardForCrewModal from '@/views/timesheet/TimeCardForCrewModal.vue';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { Eye, Search, X, AlertTriangle } from 'lucide-vue';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { store } from '@/store';
import Treeselect from '@riophae/vue-treeselect';
import CustomPagination from '@/components/Budget/CustomPagination';
import _ from 'lodash';
import { submitSelectedTimesheet } from '@/cruds/timesheet.crud';
import { getTextFromMenuNumberAndMenuValue } from '../../../cruds/language.crud';
import { NAME_CARDS_TIMESHEET_EFC, FILTER_STRING_TIME_SHEET, ACTION_EFC_TIMESHEET } from '@/utils/utils';
import { generateSecureId } from '@/shared/utils';

const validatedColor = (validated) => {
	let retval = 'not-submitted';
	if (validated == 1) {
		retval = 'info';
	} else if (validated == 2) {
		retval = 'pending';
	} else if (validated == 8) {
		retval = 'validated';
	} else if (validated == 4 || validated == 16) {
		retval = 'refused';
	}
	return retval;
};

export default {
	name: 'TimesheetsPerDateView',
	components: {
		Eye,
		Search,
		X,
		Treeselect,
		AlertTriangle,
		CustomPagination,
		TimeCardForCrewModal
	},
	props: {
		deleteAction: {
			type: Function,
			required: false
		},
		timeCards: {
			type: Array,
			required: true
		},
		tsfields: {
			type: Array,
			required: true
		},
		id: {
			type: String,
			required: false
		},
		groupByFilter: {
			type: String,
			required: false
		},
		totalAllPageRecords: {
			type: Number,
			required: false
		}
	},
	mixins: [languageMessages, GlobalMixin],
	data: () => {
		const { timecards_One, timesheets_Two } = NAME_CARDS_TIMESHEET_EFC;
		const { groupByDate } = FILTER_STRING_TIME_SHEET;
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		const setTotalOvt = (hoursOvts, minutesOvts) => {
			let hours = hoursOvts.reduce((a, b) => +a + +b, 0);
			let minutes = minutesOvts.reduce((a, b) => +a + +b, 0);
			return `${+hours <= 9 ? '0' + hours : hours}` + ':' + `${+minutes <= 9 ? '0' + minutes : minutes}`;
		};
		const setWorked = (hour, minute) => {
			return `${+hour <= 9 ? '0' + hour : hour}` + `${':' + minute}`;
		};
		return {
			setTotalOvt,
			setWorked,
			pages: [],
			hv: 'dark',
			startPage: 1,
			itemIsNotSubmitted: [],
			tsData: [],
			filter: '',
			isActionCrud: '',
			totalPage: 0,
			notSumbmittedItem: false,
			isShowSalaryDetail: false,
			showGridDetails: false,
			editData: {},
			addData,
			updateData,
			deleteData,
			update: false,
			Open: false,
			setPage: 10,
			allTsData: [],
			dataTable: [],
			Fields: [],
			Timesheets: [],
			timecards_One,
			timesheets_Two,
			groupByDate,
			persitendData: {},
			disableButtonNext: false,
			disableButtonPrev: false,
			nextValue: 1,
			prevValue: 1,
			positionNext: 0,
			prositionPrev: 0,
			recordOffSet: 0,
			recordLimit: 10,
			totalPage: 0,
			isSubmittedStatus: false,
			isLoading: false,
			generateSecureId,
			useFormInModal: true
		};
	},
	created() {
		this.initialize();
	},
	watch: {
		timeCards: {
			handler(val) {
				this.dataTable = val;
				for (let i = 0; i < this.dataTable.length; i++) {
					if (this.dataTable[i].status === 0) {
						this.isSubmittedStatus = true;
						break;
					} else {
						this.isSubmittedStatus = false;
					}
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		initialize() {
			this.Fields = this.tsfields;
			this.dataTable = this.timeCards;
			this.setFilterSize();
		},
		encodedBy(data) {
			return `${data.encoder.name} ${data.encoder.firstName}`;
		},
		async deleteActionTables(item) {
			let result = await this.deleteAction(item);
			return result && this.$emit('refreshRequested', true);
		},
		handleRefreshData(result) {
			this.$emit('refreshRequested', true);
		},
		hideModal() {
			this.Open = false;
			this.editData = {};
			this.isActionCrud = '';
			this.update = false;
		},
		closeModal() {
			this.Open = false;
			this.editData = {};
			this.isActionCrud = '';
			this.update = false;
		},
		handleClickNext() {
			this.recordOffSet += 1;
			this.$emit('handleNextPerDate', this.recordOffSet);
		},
		handleClickPrevious() {
			this.recordOffSet -= 1;
			this.$emit('handlePreviousPerDate', this.recordOffSet);
		},
		setPagesFunc(page) {
			this.isLoading = true;
			this.recordLimit = page;
			this.setPage = page;
			this.recordOffSet = 0;
			if (this.id === this.timecards_One) {
				if (this.groupByFilter === this.groupByDate) {
					this.$emit('changePageSelectLimit', this.recordLimit);
				}
			}
			this.isLoading = false;
		},
		setFilterSize() {
			const pageSizes = [10, 20, 30, 50, 100, 200];
			let arrayPage = [];
			for (let i = 0; i < pageSizes.length; i++) {
				const pageSize = pageSizes[i];
				arrayPage.push(pageSize);
			}
			this.pages = arrayPage;
		},
		showSalaryAction(e) {
			this.isShowSalaryDetail = e;
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(112, 'D');
			} else if (type == 1) {
				return this.FormMSG(113, 'W');
			} else if (type == 2) {
				return this.FormMSG(111, 'H');
			}
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.displayCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.displayCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(115, 'Phone ') +
						'(' +
						this.rendAllowanceType(item.contract.phoneAllowanceRate) +
						'): ' +
						this.displayCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.displayCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.displayCurrency(item.boxKitAllowance)
				);
			}

			if (item.mileageToSetAllowance > 0) {
				content.push(`${this.FormMSG(129, 'Mileage to set ')} : ${this.displayCurrency(item.mileageToSetAllowance)}`);
			}

			return content.join('<br />');
		},
		tooltipContentPerIdem(item) {
			let content = [];

			if (this.showHotelPerDiem() && item.hotel && item.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.displayCurrency(item.hotelPerDiem));
			}

			if (this.showLunchPerDiem() && item.lunch && item.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.displayCurrency(item.lunchPerDiem));
			}

			if (this.showDinnerPerDiem() && item.useDinner && item.dinnerPerDiem > 0) {
				content.push(this.FormMSG(212, 'Dinner: ') + this.displayCurrency(item.dinnerPerDiem));
			}

			if (this.showAbroadPerDiem() && item.useAbroad && item.abroadPerDiem > 0) {
				content.push(this.FormMSG(211, 'Abroad: ') + this.displayCurrency(item.abroadPerDiem));
			}

			return content.join('<br />');
		},
		rendColor(status) {
			return validatedColor(status);
		},
		rowClicked(item) {
			this.update = true;
			this.showModal(item);
		},
		showModal(item) {
			this.editData = item;
			this.isActionCrud = this.updateData;
			this.Open = true;
		},
		showLunchPerDiem() {
			return store.state.myProject.useLunchPerDiem;
		},
		showHotelPerDiem() {
			return store.state.myProject.useHotelPerDiem;
		},
		showDinnerPerDiem() {
			return store.state.myProject.useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.state.myProject.useAbroadPerDiem;
		},
		convertTime(time) {
			return moment(new Date(time)).format('HH:mm');
		},
		setSommeOvt(ovt, ovt1, ovt2) {
			let overtime = +ovt;
			let overtime1 = +ovt1;
			let overtime2 = +ovt2;
			let sum = overtime + overtime1 + overtime2;
			return this.displayCurrency(sum);
		},
		tooltipContentOvertime(item) {
			let content = [];
			if (item.salaryOvertime > 0) {
				content.push(
					this.FormMSG(360, 'Overtime') +
						': ' +
						this.setWorked(item.hoursOvertime, item.minutesOvertime) +
						' | ' +
						this.displayCurrency(item.salaryOvertime)
				);
			}

			if (item.salaryOvertime1 > 0) {
				content.push(
					this.FormMSG(361, 'Overtime 1') +
						': ' +
						this.setWorked(item.hoursOvertime1, item.minutesOvertime1) +
						' | ' +
						this.displayCurrency(item.salaryOvertime1)
				);
			}

			if (item.salaryOvertime2 > 0) {
				content.push(
					this.FormMSG(363, 'Overtime 2') +
						': ' +
						this.setWorked(item.hoursOvertime2, item.minutesOvertime2) +
						' | ' +
						this.displayCurrency(item.salaryOvertime2)
				);
			}

			return content.join('<br />');
		},
		handleChangeTimesheetsEfcSelected($event, data) {
			if ($event) {
				this.itemIsNotSubmitted = [...this.itemIsNotSubmitted, data];
			} else {
				this.itemIsNotSubmitted = this.itemIsNotSubmitted.filter((item) => item.id !== data.id);
			}
			return this.itemIsNotSubmitted;
		},
		selectAllNotSubmitted(e) {
			this.notSumbmittedItem = e;
			let result = this.timeCards.map((item) => {
				if (item.status !== 0) {
					return { ...item, isSelected: false };
				} else {
					if (this.notSumbmittedItem) {
						return { ...item, isSelected: true };
					}
					return { ...item, isSelected: false };
				}
			});
			this.timeCards = result;
			this.itemIsNotSubmitted = result.filter((item) => item.status === 0 && item.isSelected === true);
		},
		async setSubmitData() {
			let new_data = this.tsData;
			for (let i = 0; i < this.itemIsNotSubmitted.length; i++) {
				new_data = new_data.map((item) => {
					if (item.status === this.itemIsNotSubmitted[0].status && item.isSelected === this.itemIsNotSubmitted[0].isSelected) {
						return { ...item, status: 1, validatedText: getTextFromMenuNumberAndMenuValue(1008, 1) };
					}
					return item;
				});
			}
			this.tsData = new_data;
		},
		async sendForValidation() {
			for (let i = 0; i < this.itemIsNotSubmitted.length; i++) {
				let tsDaysToSend = +this.itemIsNotSubmitted[i].tsDay.id;
				await submitSelectedTimesheet(tsDaysToSend);
			}
			this.itemIsNotSubmitted = [];
			if (this.itemIsNotSubmitted.length === 0) {
				let strTitle = this.FormMSG(9, 'Success');
				this.$bvToast.toast(this.FormMSG(13, 'Timesheets successfully sent for validation'), {
					title: strTitle,
					variant: 'success',
					toaster: 'b-toaster-top-center',
					solid: true
				});
			}
			this.notSumbmittedItem = false;
			this.$emit('refreshDataItemPerDate');
			for (let i = 0; i < this.dataTable.length; i++) {
				if (this.dataTable[i].status === 0) {
					this.isSubmittedStatus = true;
					break;
				} else {
					this.isSubmittedStatus = false;
				}
			}
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		},
		startTotalPageNow() {
			let result = this.recordOffSet * this.setPage + this.setPage - this.setPage;
			return result;
		},
		totalPageNow() {
			let sum = this.recordOffSet * this.setPage + this.setPage;
			return sum < this.totalAllPageRecords ? sum : this.totalAllPageRecords;
		}
	}
};
</script>
