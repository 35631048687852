<style></style>
<template>
	<b-row v-if="useComponent">
		<div class="container-layout">
			<div class="p-0 card-collapsible">
				<div class="py-3">
					<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('show', Timesheets[0])">
						<div class="font-weight-bold fs-16 w-90">
							{{ setFullTitle }}
						</div>
						<div class="d-flex justify-content-end w-10">
							<component v-if="!isCollapsed" :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" color="#06263E" :size="22" />
							<component v-if="isCollapsed" :is="getLucideIcon(ICONS.CHEVRON_UP.name)" color="#06263E" :size="22" />
						</div>
					</div>
					<b-collapse :visible="isCollapsed">
						<div class="mt-3 px-2">
							<b-row align-h="between" v-if="useComponent">
								<b-col md="auto" class="pb-2 ml-1" v-show="isSubmittedStatus">
									<div v-show="notSumbmittedItem">
										<b-form-checkbox
											size="lg"
											v-model="notSumbmittedItem"
											class="pj-cb"
											:value="true"
											:unchecked-value="false"
											@change="selectAllNotSubmitted"
										>
											<div style="margin-top: 1px">{{ this.FormMSG(13, 'Unselect all not submitted') }}</div>
										</b-form-checkbox>
									</div>
									<div v-show="!notSumbmittedItem">
										<b-form-checkbox
											size="lg"
											v-model="notSumbmittedItem"
											class="pj-cb"
											:value="true"
											:unchecked-value="false"
											@change="selectAllNotSubmitted"
										>
											<div style="margin-top: 1px">{{ this.FormMSG(1, 'Select all not submitted') }}</div>
										</b-form-checkbox>
									</div>
								</b-col>
								<b-col md="auto" class="pb-2 ml-1" v-show="!isSubmittedStatus"> </b-col>
								<b-col md="auto" class="pb-2 ml-1">
									<b-button
										:disabled="itemsSelected.length === 0 && itemIsNotSubmitted.length === 0"
										@click="sendForValidation"
										class="btn btn-primary pull-right block-sm"
									>
										{{ this.FormMSG(2, 'Send for validation') }}
									</b-button>
								</b-col>
							</b-row>
							<b-row>
								<div class="d-flex flex-row align-items-center mt-2 w-100">
									<b-col cols="5" class="pb-3">
										<b-input-group>
											<b-form-input v-model="filter" type="text" :placeholder="this.FormMSG(3, 'Type to Search')" />
											<b-input-group-append class="cursor-pointer">
												<b-input-group-text class="btn-search">
													<component
														:is="getLucideIcon(ICONS.SEARCH.name)"
														:color="ICONS.SEARCH.color"
														:size="16"
														class="icon"
														:stroke-width="2.5"
														v-if="filter.length === 0"
													/>
													<component
														:is="getLucideIcon(ICONS.X.name)"
														:color="ICONS.X.color"
														:size="16"
														class="icon"
														:stroke-width="2.5"
														@click="filter = ''"
														v-else
													/>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-col>
									<b-col cols="7">
										<b-row>
											<b-col cols="6" class="d-flex align-items-center pb-3">
												<div class="mr-3 text-color-australien">
													<div class="fs-14">
														<b
															>{{ debutTotalPageNow() }} - {{ totalPageNow() }} {{ this.FormMSG(25, 'of') }}
															{{ totalAllPageRecords }} {{ this.FormMSG(26, 'records') }}</b
														>
													</div>
												</div>
											</b-col>
											<b-col cols="6">
												<div class="float-right">
													<div class="d-flex w-100">
														<div class="mr-3 w-100-px">
															<v-select
																v-model="setPage"
																:options="pages"
																@option:selected="setPagesFunc"
																label="text"
																:reduce="(option) => option.value"
																:clearable="false"
															/>
														</div>
														<b-button
															variant="primary"
															size="sm"
															class="d-flex align-items-center mr-2 px-3"
															:disabled="debutTotalPageNow() === 0 || disableButtonPrev"
															@click="handleClickPrevious"
														>
															<component :is="getLucideIcon(ICONS.SKIP_BACK.name)" :color="ICONS.SKIP_BACK.color" :size="16" />
															<div class="ml-2" style="margin-top: 1px">{{ FormMSG(23, 'Prev') }}</div>
														</b-button>
														<b-button
															class="d-flex align-items-center px-3"
															variant="primary"
															size="sm"
															:disabled="totalPageNow() === totalAllPageRecords || disableButtonNext"
															@click="handleClickNext"
														>
															<div class="mr-2" style="margin-top: 1px">{{ FormMSG(24, 'Next') }}</div>
															<component
																:is="getLucideIcon(ICONS.SKIP_FORWARD.name)"
																:color="ICONS.SKIP_FORWARD.color"
																:size="16"
															/>
														</b-button>
													</div>
												</div>
											</b-col>
										</b-row>
									</b-col>
								</div>
							</b-row>
							<b-row align-h="between">
								<b-col cols="6" class="pb-0" v-if="!isTimesheet">
									<b-form-group>
										<b-form-checkbox
											v-model="isShowSalaryDetail"
											@change="showSalaryAction($event)"
											:id="`showGridDetails${i}`"
											size="lg"
											class="pj-cb pb-1"
										>
											{{ this.FormMSG(4, 'Show salary details') }}
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="useComponent">
								<b-col cols="12" xl="12">
									<b-table
										v-if="$screen.md"
										:hover="hover"
										responsive="sm"
										:filter="filter"
										:items="dataTable"
										style="text-align: center"
										:fields="Fields"
										:current-page="this.$props.currentpage"
										:per-page="this.$props.perpage"
										:empty-text="this.FormMSG(5, 'No timesheet found')"
										bordered
										small
										sticky-header="700px"
										:head-variant="this.$props.hv"
										show-empty
										@row-clicked="showDetailTimesheet"
									>
										<template #cell(select)="data">
											<span v-show="data.item.status === 0">
												<b-form-checkbox
													:id="generateSecureId(`timesheets-efc-selected-${data.item.id + Math.random()}`)"
													:name="generateSecureId(`timesheetsEfcSelected${data.item.id}`)"
													v-model="data.item.isSelected"
													size="lg"
													class="pj-cb pb-1"
													@change="handleChangeTimesheetsEfcSelected($event, data.item)"
												/>
											</span>
										</template>
										<template #cell(status)="data">
											<div class="wrap-status">
												<div :class="`status ${classStatus(data.item.status)}`" style="font-size: 0.7rem">
													{{ getLabelStatus(data.item.status) }}
												</div>
											</div>
										</template>
										<template #cell(worked)="data">
											<div>{{ data.item.timesheet.hours }}:{{ data.item.timesheet.minutes }}</div>
										</template>
										<template #cell(encoder)="data">
											<div>{{ encodedBy(data.item.timesheet) }}</div>
										</template>
										<template #cell(night)="data">
											<div>{{ data.item.timesheet.hoursNight }}:{{ data.item.timesheet.minutesNight }}</div>
										</template>
										<template #cell(transport)="data">
											<div>{{ data.item.timesheet.hoursTransportTimePaid }}:{{ data.item.timesheet.minutesTransportTimePaid }}</div>
										</template>
										<template #cell(anticipated)="data">
											<div>{{ data.item.timesheet.hoursTooEarly }}:{{ data.item.timesheet.minutesTooEarly }}</div>
										</template>
										<template #cell(lunch)="data">
											<div>
												{{ data.item.timesheet.weeklyLunchPerDiem }}
											</div>
										</template>
										<template #cell(perDiem)="data">
											<div>{{ formatCurrency(data.item.timesheet.weeklyTotPerDiem) }}</div>
										</template>
										<template #cell(dailyOvt)="data">
											<div>{{ data.item.timesheet.hoursOvertime }}:{{ data.item.timesheet.minutesOvertime }}</div>
										</template>
										<template #cell(allowances)="data">
											<div v-if="data.item.timesheet.weeklyTotAllowances > 0">
												{{ formatCurrency(data.item.timesheet.weeklyTotAllowances) }}
											</div>
										</template>
										<template #cell(weeklyOvt)="data">
											<div>{{ data.item.timesheet.weeklyTotalOvertimeStr }}</div>
											<div v-if="isShowSalaryDetail">
												<div>{{ formatCurrency(data.item.timesheet.weeklySalaryOvt) }}</div>
												<div>{{ formatCurrency(data.item.timesheet.weeklySalaryOvt1) }}</div>
												<div>{{ formatCurrency(data.item.timesheet.weeklySalaryOvt2) }}</div>
											</div>
										</template>
										<template #cell(co2)="data">
											<div>{{ formatCo2(data.item.co2) }}</div>
										</template>
										<template #cell(salary)="data">
											<div>{{ formatCurrency(data.item.salary) }}</div>
										</template>
										<template #cell(remove)="data">
											<div v-if="data.item.status === 0 || data.item.status === 4 || data.item.status === 16">
												<component
													:is="getLucideIcon(ICONS.TRASH.name)"
													:color="ICONS.TRASH.color"
													:size="20"
													@click.stop.prevent="deleteActionTables(data.item, id)"
												/>
											</div>
										</template>
									</b-table>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
				</div>
			</div>
		</div>
	</b-row>
	<b-row v-else>
		<div class="container-layout">
			<div class="p-0 card-collapsible">
				<div class="py-3">
					<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('show', Timesheets[0])">
						<div class="font-weight-bold fs-16 w-90">{{ setFullTitle }}</div>
						<div class="d-flex justify-content-end w-10">
							<component v-if="!isCollapsed" :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" color="#06263E" :size="22" />
							<component v-if="isCollapsed" :is="getLucideIcon(ICONS.CHEVRON_UP.name)" color="#06263E" :size="22" />
						</div>
					</div>
					<b-collapse :visible="isCollapsed">
						<div class="mt-3 px-2">
							<b-row align-h="between">
								<b-col md="auto" class="pb-2 ml-1" v-show="isSubmittedStatus">
									<div v-show="notSumbmittedItem">
										<b-form-checkbox
											size="lg"
											v-model="notSumbmittedItem"
											class="pj-cb"
											:value="true"
											:unchecked-value="false"
											@change="selectAllNotSubmitted"
										>
											<div style="margin-top: 1px">{{ this.FormMSG(13, 'Unselect all not submitted') }}</div>
										</b-form-checkbox>
									</div>
									<div v-show="!notSumbmittedItem">
										<b-form-checkbox
											size="lg"
											v-model="notSumbmittedItem"
											class="pj-cb"
											:value="true"
											:unchecked-value="false"
											@change="selectAllNotSubmitted"
										>
											<div style="margin-top: 1px">{{ this.FormMSG(1, 'Select all not submitted') }}</div>
										</b-form-checkbox>
									</div>
								</b-col>
								<b-col md="auto" class="pb-2 ml-1" v-show="!isSubmittedStatus"></b-col>
								<b-col md="auto" class="pb-2 ml-1">
									<b-button
										:disabled="itemsSelected.length === 0 && itemIsNotSubmitted.length === 0"
										@click="sendForValidation"
										class="btn btn-primary pull-right block-sm"
									>
										{{ this.FormMSG(2, 'Send for validation') }}
									</b-button>
								</b-col>
							</b-row>
							<b-row>
								<div class="d-flex flex-row align-items-center mt-2 w-100">
									<b-col cols="5" class="pb-3">
										<b-input-group>
											<b-form-input v-model="filter" type="text" placeholder="Type to Search" />
											<b-input-group-append class="cursor-pointer">
												<b-input-group-text class="btn-search">
													<component
														:is="getLucideIcon(ICONS.SEARCH.name)"
														:color="ICONS.SEARCH.color"
														:size="16"
														class="icon"
														:stroke-width="2.5"
														v-if="filter.length === 0"
													/>
													<component
														:is="getLucideIcon(ICONS.X.name)"
														:color="ICONS.X.color"
														:size="16"
														class="icon"
														:stroke-width="2.5"
														@click="filter = ''"
														v-else
													/>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-col>
									<b-col cols="7">
										<b-row>
											<b-col cols="6" class="d-flex align-items-center pb-3">
												<div class="mr-3 text-color-australien">
													<div class="fs-14">
														<b
															>{{ debutTotalPageNow() }} - {{ totalPageNow() }} {{ this.FormMSG(25, 'of') }}
															{{ totalAllPageRecords }} {{ this.FormMSG(26, 'records') }}</b
														>
													</div>
												</div>
											</b-col>
											<b-col cols="6">
												<div class="float-right">
													<div class="d-flex w-100">
														<div class="mr-3 w-100-px">
															<v-select
																v-model="setPage"
																:options="pages"
																@option:selected="setPagesFunc"
																label="text"
																:reduce="(option) => option.value"
																:clearable="false"
															/>
														</div>
														<b-button
															variant="primary"
															size="sm"
															class="d-flex align-items-center mr-2 px-3"
															:disabled="debutTotalPageNow() === 0 || disableButtonPrev"
															@click="handleClickPrevious"
														>
															<component :is="getLucideIcon(ICONS.SKIP_BACK.name)" :color="ICONS.SKIP_BACK.color" :size="16" />
															<div class="ml-2" style="margin-top: 1px">{{ FormMSG(23, 'Prev') }}</div>
														</b-button>
														<b-button
															class="d-flex align-items-center px-3"
															variant="primary"
															size="sm"
															:disabled="totalPageNow() === totalAllPageRecords || disableButtonNext"
															@click="handleClickNext"
														>
															<div class="mr-2" style="margin-top: 1px">{{ FormMSG(24, 'Next') }}</div>
															<component
																:is="getLucideIcon(ICONS.SKIP_FORWARD.name)"
																:color="ICONS.SKIP_FORWARD.color"
																:size="16"
															/>
														</b-button>
													</div>
												</div>
											</b-col>
										</b-row>
									</b-col>
								</div>
							</b-row>
							<b-row align-h="between">
								<b-col cols="6" class="pb-0" v-if="!isTimesheet">
									<b-form-group>
										<b-form-checkbox
											v-model="isShowSalaryDetail"
											@change="showSalaryAction($event)"
											:id="`showGridDetails${i}`"
											size="lg"
											class="pj-cb pb-1"
										>
											{{ this.FormMSG(6, 'Show salary details') }}
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="12" xl="12">
									<b-table
										v-if="$screen.md"
										:filter="filter"
										:hover="hover"
										responsive="sm"
										:items="dataTable"
										style="text-align: center"
										:fields="Fields"
										:current-page="this.$props.currentpage"
										:per-page="this.$props.perpage"
										:empty-text="this.FormMSG(7, 'No timesheet found')"
										bordered
										small
										sticky-header="700px"
										:head-variant="this.$props.hv"
										show-empty
										@row-clicked="rowClicked"
									>
										<template #cell(select)="data">
											<span v-show="data.item.status === 0">
												<b-form-checkbox
													:id="generateSecureId(`timesheets-efc-selected-${data.item.id + Math.random()}`)"
													:name="generateSecureId(`timesheetsEfcSelected${data.item.id}`)"
													v-model="data.item.isSelected"
													size="lg"
													class="pj-cb pb-1"
													@change="handleChangeTimesheetsEfcSelected($event, data.item)"
												/>
											</span>
										</template>
										<template #cell(crew_member)="data">
											<div>
												{{ data.item.crew_member }}
											</div>
										</template>
										<template #cell(status)="data">
											<div class="wrap-status">
												<div :class="`status ${classStatus(data.item.status)}`" style="font-size: 0.7rem">
													{{ getLabelStatus(data.item.status) }}
												</div>
											</div>
										</template>

										<template #cell(worked)="data">
											<div>{{ setWorked(data.item.tsDay.hours, data.item.tsDay.minutes) }}</div>
										</template>
										<template #cell(encoder)="data">
											<div>{{ encodedBy(data.item.tsDay) }}</div>
										</template>
										<template #cell(dayType)="data">
											<div>
												{{ getFieldValueByShowGridDetails(showGridDetails, data.item.tsDay.dayType, data.item.tsDay.salaryBase) }}
											</div>
											<div v-if="isShowSalaryDetail">{{ formatCurrency(data.item.tsDay.salaryBase) }}</div>
										</template>
										<template #cell(ovt)="data">
											<div class="d-flex flex-row justify-content-center" style="gap: 4px">
												<button
													v-if="
														setTotalOvt(
															[data.item.tsDay.hoursOvertime, data.item.tsDay.hoursOvertime1, data.item.tsDay.hoursOvertime2],
															[
																data.item.tsDay.minutesOvertime,
																data.item.tsDay.minutesOvertime1,
																data.item.tsDay.minutesOvertime2
															]
														) !== '00:00'
													"
													class="btn-transparent text-color-rhapsody-in-blue"
													v-b-tooltip.hover.left.html="tooltipContentOvertime(data.item.tsDay)"
												>
													<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" />
												</button>
												<div>
													{{
														setTotalOvt(
															[data.item.tsDay.hoursOvertime, data.item.tsDay.hoursOvertime1, data.item.tsDay.hoursOvertime2],
															[
																data.item.tsDay.minutesOvertime,
																data.item.tsDay.minutesOvertime1,
																data.item.tsDay.minutesOvertime2
															]
														)
													}}
												</div>
											</div>
											<div v-if="isShowSalaryDetail">
												<div>
													{{
														setSommeOvt(
															data.item.tsDay.salaryOvertime,
															data.item.tsDay.salaryOvertime1,
															data.item.tsDay.salaryOvertime2
														)
													}}
												</div>
											</div>
										</template>
										<template #cell(lunch)="data">
											<div>
												{{
													!data.item.tsDay.notSpecifiedLunch
														? `${convertTime(data.item.tsDay.lunchStrTime)} - ${convertTime(data.item.tsDay.lunchEndTime)}`
														: '-'
												}}
											</div>
										</template>
										<template #cell(night)="data">
											<div>{{ data.item.tsDay.hoursNight.toString().padStart('2', '0') }}:{{ data.item.tsDay.minutesNight }}</div>
											<div v-if="isShowSalaryDetail">{{ formatCurrency(data.item.tsDay.salaryHourNight) }}</div>
										</template>
										<template #cell(anticipated)="data">
											<div>{{ data.item.tsDay.hoursTooEarly.toString().padStart('2', '0') }}:{{ data.item.tsDay.minutesTooEarly }}</div>
											<div v-if="isShowSalaryDetail">{{ formatCurrency(data.item.tsDay.salaryHourTooEarly) }}</div>
										</template>
										<template
											#cell(totPerDiem)="data"
											v-if="showLunchPerDiem || showHotelPerDiem || showDinnerPerDiem || showAbroadPerDiem"
										>
											<div class="d-flex flex-row justify-content-center" style="gap: 4px">
												<button
													v-if="data.item.tsDay.totPerDiem > 0"
													class="btn-transparent text-color-rhapsody-in-blue"
													v-b-tooltip.hover.left.html="tooltipContentPerIdem(data.item.tsDay)"
												>
													<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" />
												</button>
												<div>{{ formatCurrency(data.item.tsDay.totPerDiem) }}</div>
											</div>
										</template>
										<template #cell(dayRate)="data">
											<div>{{ data.item.tsDay.dayRate }}%</div>
										</template>

										<template #cell(transport)="data">
											<div>
												{{ data.item.tsDay.hoursTransportTimePaid.toString().padStart('2', '0') }}:{{
													data.item.tsDay.minutesTransportTimePaid
												}}
											</div>
											<div v-if="isShowSalaryDetail">
												<div>{{ formatCurrency(data.item.tsDay.salaryTransport) }}</div>
											</div>
										</template>
										<template #cell(co2)="data">
											<div>{{ formatCo2(data.item.tsDay.kgCoTwo) }}</div>
										</template>
										<template #cell(allowances)="data">
											<div class="d-flex flex-row justify-content-center" style="gap: 4px">
												<button
													v-if="data.item.tsDay.totAllowances > 0"
													class="btn-transparent text-color-rhapsody-in-blue"
													v-b-tooltip.hover.left.html="tooltipContentAllowance(data.item.tsDay)"
												>
													<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" />
												</button>
												<div>{{ formatCurrency(data.item.tsDay.totAllowances) }}</div>
											</div>
										</template>
										<template #cell(salary)="data">
											<div>{{ formatCurrency(data.item.tsDay.salary) }}</div>
										</template>
										<template #cell(remove)="data">
											<div v-if="data.item.status === 0 || data.item.status === 4 || data.item.status === 16">
												<component
													:is="getLucideIcon(ICONS.TRASH.name)"
													:color="ICONS.TRASH.color"
													:size="20"
													@click.stop.prevent="deleteActionTables(data.item, id)"
												/>
											</div>
										</template>
									</b-table>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
				</div>
			</div>
			<time-card-for-crew-modal
				:update="update"
				:editData="editData"
				:isOpen="Open"
				:showModal="showModal"
				:hideModal="hideModal"
				:isActionCrud="isActionCrud"
				:addDataFunc="(e) => addDataFunc(e)"
				:refreshDataItem="(item) => refreshDataItem(item)"
				@add:Submitted="handleRefreshData"
				@closeModal="closeModal"
				:screenUsedTheForm="`entry-for-crew`"
				:useFormInModal="useFormInModal"
			/>
		</div>
	</b-row>
</template>
<script>
import TimeCardForCrewModal from '@/views/timesheet/TimeCardForCrewModal.vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { classStatus } from '@/shared/global-status';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { getProjectUserTsDays, getProjectUserSalariesTimesheetsEfc, submitTimeSheetsSlice } from '@/cruds/timesheets-efc.crud';
import { submitSelectedTimesheet } from '@/cruds/timesheet.crud';
import { FILTER_STRING_TIME_SHEET, NAME_CARDS_TIMESHEET_EFC, ACTION_EFC_TIMESHEET } from '@/utils/utils';
import { generateSecureId } from '@/shared/utils';
import { store } from '@/store';
import moment from 'moment';
export default {
	name: 'TimesheetsEfcTables',
	components: { TimeCardForCrewModal },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		isTimesheet: {
			typer: Boolean,
			required: false
		},
		hover: {
			type: Boolean,
			required: false
		},
		timesheets: {
			type: Array,
			required: false
		},
		tsfields: {
			type: Array,
			required: false
		},
		currentpage: {
			type: Number,
			required: false
		},
		perpage: {
			type: Number,
			required: false
		},
		hv: {
			type: String,
			required: false
		},
		i: {
			type: String,
			required: false
		},
		useComponent: {
			type: Boolean,
			require: false
		},
		groupByFilter: {
			type: String,
			required: false
		},
		id: {
			type: String,
			required: false
		},
		deleteAction: {
			type: Function,
			required: false
		},
		addDataFunc: {
			type: Function,
			required: false
		}
	},
	data: () => {
		const { groupByPerson, groupByDate, groupByStatus } = FILTER_STRING_TIME_SHEET;
		const { timecards_One, timesheets_Two } = NAME_CARDS_TIMESHEET_EFC;
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		const setTotalOvt = (hoursOvts, minutesOvts) => {
			let hours = hoursOvts.reduce((a, b) => +a + +b, 0);
			let minutes = minutesOvts.reduce((a, b) => +a + +b, 0);
			return `${hours.toString().padStart('2', '0')}` + ':' + `${minutes.toString().padStart('2', '0')}`;
		};
		const setWorked = (hour, minute) => {
			return `${hour.toString().padStart('2', '0')}` + ':' + `${minute.toString().padStart('2', '0')}`;
		};
		return {
			update: false,
			Open: false,
			isActionCrud: '',
			addData,
			updateData,
			deleteData,
			editData: {},
			setTotalOvt,
			setWorked,
			isCollapsed: false,
			Timesheets: [],
			Fields: [],
			classStatus,
			fullName: '',
			isShowing: true,
			groupByPerson,
			groupByDate,
			groupByStatus,
			fullTitle: '',
			filter: '',
			timecards_One,
			timesheets_Two,
			dataTable: [],
			itemsSelected: [],
			itemIsNotSubmitted: [],
			setPage: 10,
			next: 10,
			prev: 10,
			pages: [],
			allDataTable: [],
			isShowSalaryDetail: false,
			showGridDetails: false,
			dataTypeTest: 0,
			notSumbmittedItem: false,
			isSelectAllNotSubmitted: false,
			persitendData: {},
			disableButtonNext: false,
			disableButtonPrev: false,
			nextValue: 1,
			prevValue: 1,
			positionNext: 0,
			prositionPrev: 0,
			recordOffSet: 0,
			recordLimit: 10,
			totalPage: 0,
			isSubmittedStatus: false,
			isLoading: false,
			totalAllPageRecords: 0,
			generateSecureId,
			useFormInModal: true
		};
	},
	watch: {
		Fields: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		allDataTable: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		pages: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		dataTable: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		totalPage: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		notSumbmittedItem: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.initialize();
	},
	computed: {
		setDisablePrev() {
			return this.nextValue === 1 || this.positionNext === 1 ? true : false;
		},
		setDisableNext() {
			return this.allDataTable.length <= 10 ? true : false;
		},
		setFullTitle() {
			if (this.id === this.timecards_One) {
				if (this.groupByFilter === this.groupByPerson) {
					if (this.Timesheets !== undefined) {
						this.fullTitle = this.Timesheets[0].fullName + (this.Timesheets[0].functionName && ` - ${this.Timesheets[0].functionName}`);
						return this.fullTitle;
					}
				}
				if (this.groupByFilter === this.groupByDate) {
					this.fullTitle = moment(this.Timesheets[0].date).format('DD/MM/YYYY');
					return this.fullTitle;
				}
				if (this.groupByFilter === this.groupByStatus) {
					if (this.Timesheets !== undefined) {
						this.fullTitle = this.getLabelStatus(this.Timesheets[0].status).toUpperCase();
						return this.fullTitle;
					}
				}
			} else {
				//timecard_two
				if (this.id === this.timesheets_Two && this.groupByFilter === this.groupByPerson) {
					if (this.Timesheets !== undefined) {
						this.fullTitle = this.Timesheets[0].fullName + (this.Timesheets[0].functionName && ` - ${this.Timesheets[0].functionName}`);
						return this.fullTitle;
					}
				}
				if (this.id === this.timesheets_Two && this.groupByFilter === this.groupByDate) {
					if (this.Timesheets !== undefined) {
						this.fullTitle =
							moment(this.Timesheets[0].startDate).format('DD/MM/YYYY') + ' - ' + moment(this.Timesheets[0].endDate).format('DD/MM/YYYY');
						return this.fullTitle;
					}
				}
				if (this.id === this.timesheets_Two && this.groupByFilter === this.groupByStatus) {
					if (this.Timesheets !== undefined) {
						this.fullTitle = this.getLabelStatus(this.Timesheets[0].status).toUpperCase();
						return this.fullTitle;
					}
				}
			}
		},
		showOvt() {
			return store.state.myProject.useOvertime;
		},
		showOvt1() {
			return store.state.myProject.useOvt1;
		},
		showOvt2() {
			return store.state.myProject.useOvt2;
		},
		showWeeklyOvt() {
			return store.state.myProject.useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.state.myProject.useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.state.myProject.useWeeklyOvt2;
		},
		showTransport() {
			return store.state.myProject.useHomeToWorkTime;
		},
		showLunchPerDiem() {
			return store.state.myProject.useLunchPerDiem;
		},
		showHotelPerDiem() {
			return store.state.myProject.useHotelPerDiem;
		},
		showDinnerPerDiem() {
			return store.state.myProject.useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.state.myProject.useAbroadPerDiem;
		},
		showTooEarlyHours() {
			return store.state.myProject.useTooEarlyHours;
		},
		showNightHours() {
			return store.state.myProject.useNightHours;
		},
		showPreCall() {
			return store.state.myProject.useBeforeCallTime;
		}
	},
	methods: {
		encodedBy(data) {
			return `${data.encoder.name} ${data.encoder.firstName}`;
		},
		tooltipContentOvertime(item) {
			let content = [];
			if (item.salaryOvertime > 0) {
				content.push(
					this.FormMSG(360, 'Overtime') +
						': ' +
						this.setWorked(item.hoursOvertime, item.minutesOvertime) +
						' | ' +
						this.formatCurrency(item.salaryOvertime)
				);
			}

			if (item.salaryOvertime1 > 0) {
				content.push(
					this.FormMSG(361, 'Overtime 1') +
						': ' +
						this.setWorked(item.hoursOvertime1, item.minutesOvertime1) +
						' | ' +
						this.formatCurrency(item.salaryOvertime1)
				);
			}

			if (item.salaryOvertime2 > 0) {
				content.push(
					this.FormMSG(363, 'Overtime 2') +
						': ' +
						this.setWorked(item.hoursOvertime2, item.minutesOvertime2) +
						' | ' +
						this.formatCurrency(item.salaryOvertime2)
				);
			}

			return content.join('<br />');
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(112, 'D');
			} else if (type == 1) {
				return this.FormMSG(113, 'W');
			} else if (type == 2) {
				return this.FormMSG(111, 'H');
			}
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.formatCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.formatCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(115, 'Phone ') +
						'(' +
						this.rendAllowanceType(item.contract.phoneAllowanceRate) +
						'): ' +
						this.formatCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.formatCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.formatCurrency(item.boxKitAllowance)
				);
			}

			if (item.mileageToSetAllowance > 0) {
				content.push(`${this.FormMSG(129, 'Mileage to set ')} : ${this.formatCurrency(item.mileageToSetAllowance)}`);
			}

			return content.join('<br/>');
		},
		tooltipContentPerIdem(item) {
			let content = [];

			if (this.showHotelPerDiem && item.hotel && item.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.formatCurrency(item.hotelPerDiem));
			}

			if (this.showLunchPerDiem && item.lunch && item.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.formatCurrency(item.lunchPerDiem));
			}

			if (this.showDinnerPerDiem && item.useDinner && item.dinnerPerDiem > 0) {
				content.push(this.FormMSG(212, 'Dinner: ') + this.formatCurrency(item.dinnerPerDiem));
			}

			if (this.showAbroadPerDiem && item.useAbroad && item.abroadPerDiem > 0) {
				content.push(this.FormMSG(211, 'Abroad: ') + this.formatCurrency(item.abroadPerDiem));
			}

			return content.join('<br/>');
		},
		formatCo2(val) {
			return rendKgCo2(val);
		},
		formatCurrency(value) {
			return rendCurrency(value);
		},
		setSommeOvt(ovt, ovt1, ovt2) {
			let overtime = +ovt;
			let overtime1 = +ovt1;
			let overtime2 = +ovt2;
			let sum = overtime + overtime1 + overtime2;
			return this.formatCurrency(sum);
		},
		async initialize() {
			this.Timesheets = this.timesheets;
			this.Fields = this.tsfields;
			if (this.id === this.timecards_One) {
				if (this.Timesheets !== undefined) {
					const data = this.Timesheets;
					if (data.length !== 0) {
						let id = data[0].tsDay.id;
						// Get data from locale storage to persit collapse
						let _data = [...new Set(JSON.parse(localStorage.getItem('dataCollapseTab_One')))].filter((item) => +item.idCollapse === +id);
						if (_data.length !== 0) {
							this.isCollapsed = true;
							let item = this.Timesheets[0];
							let setDataFilter = {};
							if (this.isCollapsed) {
								if (this.groupByFilter === this.groupByPerson) {
									setDataFilter = {
										...this.setFilterName('personId', +item.tsDay.user.id),
										activateCrewAccessRestriction: true
									};
								}
								if (this.groupByFilter === this.groupByStatus) {
									setDataFilter = {
										...this.setFilterName('status', +item.status),
										activateCrewAccessRestriction: true
									};
								}
								if (this.groupByFilter === this.groupByDate) {
									setDataFilter = {
										...this.setFilterName('startDate', this.Timesheets[0].date),
										...this.setFilterName('endDate', this.Timesheets[0].date),
										activateCrewAccessRestriction: true
									};
								}
								this.persitendData = setDataFilter;
								let result = [...(await getProjectUserTsDays(setDataFilter))].map((item) => ({
									...item,
									crew_member: item.fullName,
									isSelected: false,
									data: [{ ...item, crew_member: item.fullName }]
								}));
								delete setDataFilter.recordLimit;
								delete setDataFilter.recordOffSet;
								let setDataFilterAllRecords = {
									...setDataFilter
								};
								this.totalAllPageRecords = [...(await getProjectUserTsDays(setDataFilterAllRecords))].length;
								this.Fields = this.tsfields;
								if (this.recordOffSet === 0) {
									this.disableButtonPrev = true;
									this.disableButtonNext = false;
								} else {
									this.disableButtonPrev = false;
									this.disableButtonNext = false;
								}
								this.allDataTable = result;
								this.pages = [...this.setFilterSize()].map((item) => {
									return { value: item, text: item };
								});
								this.dataTable = this.allDataTable;
								this.totalPage = this.dataTable.length;
								for (let i = 0; i < this.dataTable.length; i++) {
									if (this.dataTable[i].status === 0) {
										this.isSubmittedStatus = true;
										break;
									} else {
										this.isSubmittedStatus = false;
									}
								}
							}
						} else {
							this.isCollapsed = false;
						}
					}
				}
			} else {
				if (this.Timesheets !== undefined) {
					const data = this.Timesheets;
					if (data.length !== 0) {
						let id = data[0].timesheet.id;
						let _data = [...new Set(JSON.parse(localStorage.getItem('dataCollapseTab_Two')))].filter((item) => +item.idCollapse === +id);
						if (_data.length !== 0) {
							this.isCollapsed = true;
							let item = this.Timesheets[0];
							let setDataFilter = {};
							if (this.isCollapsed) {
								if (this.groupByFilter === this.groupByPerson) {
									setDataFilter = {
										...this.setFilterName('personId', +item.timesheet.user.id),
										activateCrewAccessRestriction: true
									};
								}
								if (this.groupByFilter === this.groupByStatus) {
									setDataFilter = {
										...this.setFilterName('status', +item.status),
										activateCrewAccessRestriction: true
									};
								}
								if (this.groupByFilter === this.groupByDate) {
									setDataFilter = {
										...this.setFilterName('startDate', this.Timesheets[0].startDate),
										...this.setFilterName('endDate', this.Timesheets[0].endDate),
										activateCrewAccessRestriction: true
									};
								}
								this.persitendData = setDataFilter;
								let result = [...(await getProjectUserSalariesTimesheetsEfc(setDataFilter))].map((item) => ({
									...item,
									crew_member: item.fullName,
									isSelected: false,
									data: [{ ...item, crew_member: item.fullName, isSelected: false }]
								}));
								delete setDataFilter.recordLimit;
								delete setDataFilter.recordOffSet;
								let setDataFilterAllRecords = {
									...setDataFilter
								};
								this.totalAllPageRecords = [...(await getProjectUserSalariesTimesheetsEfc(setDataFilterAllRecords))].length;
								this.Fields = this.tsfields;
								if (this.recordOffSet !== 0) {
									if (result.length !== 0) {
										if (result.length < this.recordLimit) {
											this.disableButtonNext = true;
											this.disableButtonPrev = false;
										} else {
											this.disableButtonNext = false;
											this.disableButtonPrev = false;
										}
									}
								} else {
									if (result.length < this.recordLimit) {
										this.disableButtonNext = true;
										this.disableButtonPrev = true;
									} else {
										this.disableButtonNext = false;
										this.disableButtonPrev = true;
									}
								}
								this.allDataTable = result;
								this.pages = [...this.setFilterSize()].map((item) => {
									return { value: item, text: item };
								});
								this.dataTable = this.allDataTable;
								this.totalPage = this.dataTable.length;
								for (let i = 0; i < this.dataTable.length; i++) {
									if (this.dataTable[i].status === 0) {
										this.isSubmittedStatus = true;
										break;
									} else {
										this.isSubmittedStatus = false;
									}
								}
							}
						} else {
							this.isCollapsed = false;
						}
					}
				}
			}
		},
		async getDataNext_one_cordon(setDataFilter) {
			let result = [...(await getProjectUserTsDays(setDataFilter))].map((item) => ({
				...item,
				crew_member: item.fullName,
				isSelected: false,
				data: [{ ...item, crew_member: item.fullName }]
			}));
			if (this.recordOffSet !== 0) {
				if (result.length !== 0) {
					this.allDataTable = [...this.dataTable, ...result];
					if (result.length < this.setPage) {
						this.disableButtonNext = true;
						this.disableButtonPrev = false;
					} else {
						this.disableButtonNext = false;
						this.disableButtonPrev = false;
					}
				}
			} else {
				if (result.length < this.setPage) {
					this.disableButtonNext = true;
					this.disableButtonPrev = true;
				} else {
					this.disableButtonNext = false;
					this.disableButtonPrev = true;
				}
			}
			this.pages = [...this.setFilterSize()].map((item) => {
				return { value: item, text: item };
			});
			this.dataTable = result;
			this.totalPage = this.dataTable.length;
			for (let i = 0; i < this.dataTable.length; i++) {
				if (this.dataTable[i].status === 0) {
					this.isSubmittedStatus = true;
					break;
				} else {
					this.isSubmittedStatus = false;
				}
			}
		},
		async getDataToTab_one_cordon(setDataFilter) {
			let result = [...(await getProjectUserTsDays(setDataFilter))].map((item) => ({
				...item,
				crew_member: item.fullName,
				isSelected: false,
				data: [{ ...item, crew_member: item.fullName }]
			}));
			delete setDataFilter.recordLimit;
			delete setDataFilter.recordOffSet;
			let setDataFilterAllRecords = {
				...setDataFilter
			};
			this.totalAllPageRecords = [...(await getProjectUserTsDays(setDataFilterAllRecords))].length;
			this.Fields = this.tsfields;
			this.allDataTable = result;
			this.pages = [...this.setFilterSize()].map((item) => {
				return { value: item, text: item };
			});
			this.dataTable = this.allDataTable;
			this.disableButtonPrev = true;
			if (+this.dataTable.length < +this.setPage) {
				this.disableButtonNext = true;
				this.disableButtonPrev = true;
			}
			if (+this.dataTable.length === +this.totalAllPageRecords) {
				this.disableButtonNext = true;
				this.disableButtonPrev = true;
			} else {
				if (this.recordOffSet === 0) {
					this.disableButtonNext = false;
					this.disableButtonPrev = true;
				} else {
					this.disableButtonNext = false;
					this.disableButtonPrev = false;
				}
			}
			this.totalPage = this.dataTable.length;
			for (let i = 0; i < this.dataTable.length; i++) {
				if (this.dataTable[i].status === 0) {
					this.isSubmittedStatus = true;
					break;
				} else {
					this.isSubmittedStatus = false;
				}
			}
		},
		async getDataNext_two_cordon(setDataFilter) {
			let result = [...(await getProjectUserSalariesTimesheetsEfc(setDataFilter))].map((item) => ({
				...item,
				crew_member: item.fullName,
				isSelected: false,
				data: [{ ...item, crew_member: item.fullName, isSelected: false }]
			}));
			if (this.recordOffSet !== 0) {
				if (result.length !== 0) {
					this.allDataTable = [...this.dataTable, ...result];
					if (result.length < this.setPage) {
						this.disableButtonNext = true;
						this.disableButtonPrev = false;
					} else {
						this.disableButtonNext = false;
						this.disableButtonPrev = false;
					}
				}
			} else {
				if (result.length < this.setPage) {
					this.disableButtonNext = true;
					this.disableButtonPrev = true;
				} else {
					this.disableButtonNext = false;
					this.disableButtonPrev = true;
				}
			}
			this.pages = [...this.setFilterSize()].map((item) => {
				return { value: item, text: item };
			});
			this.dataTable = result;
			this.totalPage = this.dataTable.length;
			for (let i = 0; i < this.dataTable.length; i++) {
				if (this.dataTable[i].status === 0) {
					this.isSubmittedStatus = true;
					break;
				} else {
					this.isSubmittedStatus = false;
				}
			}
		},
		async getDataToTab_two_cordon(setDataFilter) {
			let result = [...(await getProjectUserSalariesTimesheetsEfc(setDataFilter))].map((item) => ({
				...item,
				crew_member: item.fullName,
				isSelected: false,
				data: [{ ...item, crew_member: item.fullName, isSelected: false }]
			}));
			delete setDataFilter.recordLimit;
			delete setDataFilter.recordOffSet;
			let setDataFilterAllRecords = {
				...setDataFilter
			};
			this.totalAllPageRecords = [...(await getProjectUserSalariesTimesheetsEfc(setDataFilterAllRecords))].length;
			this.Fields = this.tsfields;
			this.allDataTable = result;
			this.pages = [...this.setFilterSize()].map((item) => {
				return { value: item, text: item };
			});
			this.dataTable = this.allDataTable;
			this.disableButtonPrev = true;
			if (+this.dataTable.length < +this.setPage) {
				this.disableButtonNext = true;
				this.disableButtonPrev = true;
			}
			if (+this.dataTable.length === +this.totalAllPageRecords) {
				this.disableButtonNext = true;
				this.disableButtonPrev = true;
			} else {
				if (this.recordOffSet === 0) {
					this.disableButtonNext = false;
					this.disableButtonPrev = true;
				} else {
					this.disableButtonNext = false;
					this.disableButtonPrev = false;
				}
			}
			this.totalPage = this.dataTable.length;
			for (let i = 0; i < this.dataTable.length; i++) {
				if (this.dataTable[i].status === 0) {
					this.isSubmittedStatus = true;
					break;
				} else {
					this.isSubmittedStatus = false;
				}
			}
		},
		async handleClickHeader(key, item) {
			this.recordLimit = this.setPage;
			this.positionNext = 0;
			this.recordOffSet = this.positionNext;
			let setDataFilter = {};
			this.isCollapsed = !this.isCollapsed;
			if (this.id === this.timecards_One) {
				// if click in timecards
				let _dataCollapseTab_One = [];
				let dataCollapseTab_One = JSON.parse(localStorage.getItem('dataCollapseTab_One'));
				let idCollapse = item.tsDay.id;
				if (this.isCollapsed === true && dataCollapseTab_One !== null) {
					_dataCollapseTab_One = [...dataCollapseTab_One, { idCollapse: idCollapse, value: this.isCollapsed }];
					localStorage.setItem('dataCollapseTab_One', JSON.stringify(_dataCollapseTab_One));
				} else if (this.isCollapsed === true && dataCollapseTab_One === null) {
					_dataCollapseTab_One = [{ idCollapse: idCollapse, value: this.isCollapsed }];
					localStorage.setItem('dataCollapseTab_One', JSON.stringify(_dataCollapseTab_One));
				} else {
					let result = [...JSON.parse(localStorage.getItem('dataCollapseTab_One'))].filter((_item) => _item.idCollapse !== idCollapse);
					localStorage.setItem('dataCollapseTab_One', JSON.stringify(result));
				}
				if (this.groupByFilter === this.groupByPerson) {
					setDataFilter = {
						...this.setFilterName('personId', +item.tsDay.user.id),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByStatus) {
					setDataFilter = {
						...this.setFilterName('status', +item.status),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByDate) {
					setDataFilter = {
						...this.setFilterName('startDate', this.Timesheets[0].date),
						...this.setFilterName('endDate', this.Timesheets[0].date),
						activateCrewAccessRestriction: true
					};
				}
				this.persitendData = setDataFilter;
				if (this.isCollapsed) {
					this.getDataToTab_one_cordon(setDataFilter);
				}
			}
			if (this.id === this.timesheets_Two) {
				// if click in timesheets
				let _dataCollapseTab_Two = [];
				let dataCollapseTab_Two = JSON.parse(localStorage.getItem('dataCollapseTab_Two'));
				let idCollapse = item.timesheet.id;
				if (this.isCollapsed === true && dataCollapseTab_Two !== null) {
					_dataCollapseTab_Two = [...dataCollapseTab_Two, { idCollapse: idCollapse, value: this.isCollapsed }];
					localStorage.setItem('dataCollapseTab_Two', JSON.stringify(_dataCollapseTab_Two));
				} else if (this.isCollapsed === true && dataCollapseTab_Two === null) {
					_dataCollapseTab_Two = [{ idCollapse: idCollapse, value: this.isCollapsed }];
					localStorage.setItem('dataCollapseTab_Two', JSON.stringify(_dataCollapseTab_Two));
				} else {
					let result = [...JSON.parse(localStorage.getItem('dataCollapseTab_Two'))].filter((_item) => _item.idCollapse !== idCollapse);
					localStorage.setItem('dataCollapseTab_Two', JSON.stringify(result));
				}
				if (this.groupByFilter === this.groupByPerson) {
					setDataFilter = {
						...this.setFilterName('personId', +item.timesheet.user.id),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByStatus) {
					setDataFilter = {
						...this.setFilterName('status', +item.status),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByDate) {
					setDataFilter = {
						...this.setFilterName('startDate', this.Timesheets[0].startDate),
						...this.setFilterName('endDate', this.Timesheets[0].endDate),
						activateCrewAccessRestriction: true
					};
				}
				this.persitendData = setDataFilter;
				if (this.isCollapsed) {
					this.getDataToTab_two_cordon(setDataFilter);
				}
			}
		},
		setFilterSize() {
			const pageSizes = [10, 20, 30, 50, 100, 200];
			let arrayPage = [];
			// this.setPage = 10;
			for (let i = 0; i < pageSizes.length; i++) {
				const pageSize = pageSizes[i];
				arrayPage.push(pageSize);
			}
			return arrayPage;
		},
		setFilterName(name, value) {
			let obj = {};
			obj[name] = value;
			return { ...obj, projectId: +store.projectID(), recordLimit: this.setPage, recordOffSet: this.recordOffSet };
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		},
		convertTime(time) {
			return moment(new Date(time)).format('HH:mm');
		},
		async deleteActionTables(item, id) {
			let result = await this.deleteAction(item, id);
			if (result) {
				this.dataTable = this.dataTable.filter((_item) => _item !== item);
				for (let i = 0; i < this.dataTable.length; i++) {
					if (this.dataTable[i].status === 0) {
						this.isSubmittedStatus = true;
						break;
					} else {
						this.isSubmittedStatus = false;
					}
				}
			}
		},
		setPagesFunc() {
			if (this.setPage) {
				let setDataFilter = {};
				this.isLoading = true;
				this.recordLimit = this.setPage;
				this.recordOffSet = 0;
				if (this.id === this.timecards_One) {
					if (this.groupByFilter === this.groupByPerson) {
						setDataFilter = {
							...this.setFilterName('personId', +this.Timesheets[0].tsDay.user.id),
							activateCrewAccessRestriction: true
						};
					}
					if (this.groupByFilter === this.groupByStatus) {
						setDataFilter = {
							...this.setFilterName('status', +this.Timesheets[0].status),
							activateCrewAccessRestriction: true
						};
					}
					if (this.groupByFilter === this.groupByDate) {
						setDataFilter = {
							...this.setFilterName('startDate', this.Timesheets[0].date),
							...this.setFilterName('endDate', this.Timesheets[0].date),
							activateCrewAccessRestriction: true
						};
					}
					this.persitendData = setDataFilter;
					if (this.isCollapsed) {
						this.getDataToTab_one_cordon(setDataFilter);
					}
				}
				if (this.id === this.timesheets_Two) {
					if (this.groupByFilter === this.groupByPerson) {
						setDataFilter = {
							...this.setFilterName('personId', +this.Timesheets[0].timesheet.user.id),
							activateCrewAccessRestriction: true
						};
					}
					if (this.groupByFilter === this.groupByStatus) {
						setDataFilter = {
							...this.setFilterName('status', +this.Timesheets[0].status),
							activateCrewAccessRestriction: true
						};
					}
					if (this.groupByFilter === this.groupByDate) {
						setDataFilter = {
							...this.setFilterName('startDate', this.Timesheets[0].startDate),
							...this.setFilterName('endDate', this.Timesheets[0].endDate),
							activateCrewAccessRestriction: true
						};
					}
					this.persitendData = setDataFilter;
					if (this.isCollapsed) {
						this.getDataToTab_two_cordon(setDataFilter);
					}
				}
				this.isLoading = false;
			}
		},
		showSalaryAction(e) {
			this.isShowSalaryDetail = e;
		},

		handleChangeTimesheetsEfcSelected($event, data) {
			if ($event) {
				this.itemIsNotSubmitted = [...this.itemIsNotSubmitted, data];
			} else {
				this.itemIsNotSubmitted = this.itemIsNotSubmitted.filter((item) => item.id !== data.id);
			}
			return this.itemIsNotSubmitted;
		},
		selectAllNotSubmitted(e) {
			this.notSumbmittedItem = e;
			let result = this.dataTable.map((item) => {
				if (item.status !== 0) {
					return { ...item, isSelected: false };
				} else {
					if (this.notSumbmittedItem) {
						return { ...item, isSelected: true };
					}
					return { ...item, isSelected: false };
				}
			});
			this.dataTable = result;
			this.itemIsNotSubmitted = result.filter((item) => item.status === 0 && item.isSelected === true);
		},
		async sendForValidation() {
			if (this.id === this.timecards_One) {
				for (let i = 0; i < this.itemIsNotSubmitted.length; i++) {
					let tsDaysToSend = +this.itemIsNotSubmitted[i].tsDay.id;
					await submitSelectedTimesheet(tsDaysToSend);
				}
				let setDataFilter = {};
				if (this.groupByFilter === this.groupByPerson) {
					setDataFilter = {
						...this.setFilterName('personId', +this.Timesheets[0].tsDay.user.id),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByStatus) {
					setDataFilter = {
						...this.setFilterName('status', +this.Timesheets[0].status),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByDate) {
					setDataFilter = {
						...this.setFilterName('startDate', this.Timesheets[0].date),
						...this.setFilterName('endDate', this.Timesheets[0].date),
						activateCrewAccessRestriction: true
					};
				}
				this.persitendData = setDataFilter;
				let result = [...(await getProjectUserTsDays(setDataFilter))].map((item) => ({
					...item,
					crew_member: item.fullName,
					isSelected: false,
					data: [{ ...item, crew_member: item.fullName }]
				}));
				this.allDataTable = result;
				this.dataTable = this.allDataTable;
				for (let i = 0; i < this.dataTable.length; i++) {
					if (this.dataTable[i].status === 0) {
						this.isSubmittedStatus = true;
						break;
					} else {
						this.isSubmittedStatus = false;
					}
				}
				this.itemIsNotSubmitted = [];
				if (this.itemIsNotSubmitted.length === 0) {
					let strTitle = this.FormMSG(9, 'Success');
					this.$bvToast.toast(this.FormMSG(12, 'All not submitted for validation send'), {
						title: strTitle,
						variant: 'success',
						toaster: 'b-toaster-top-center',
						solid: true
					});
				}
				this.notSumbmittedItem = false;
			}
			if (this.id === this.timesheets_Two) {
				for (let i = 0; i < this.itemIsNotSubmitted.length; i++) {
					let TimesheetToSend = +this.itemIsNotSubmitted[i].timesheet.id;
					await submitTimeSheetsSlice(TimesheetToSend);
				}
				let setDataFilter = {};
				if (this.groupByFilter === this.groupByPerson) {
					setDataFilter = {
						...this.setFilterName('personId', +this.Timesheets[0].timesheet.user.id),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByStatus) {
					setDataFilter = {
						...this.setFilterName('status', +this.Timesheets[0].status),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByDate) {
					setDataFilter = {
						...this.setFilterName('startDate', this.Timesheets[0].startDate),
						...this.setFilterName('endDate', this.Timesheets[0].endDate),
						activateCrewAccessRestriction: true
					};
				}
				this.persitendData = setDataFilter;
				let result = [...(await getProjectUserSalariesTimesheetsEfc(setDataFilter))].map((item) => ({
					...item,
					crew_member: item.fullName,
					isSelected: false,
					data: [{ ...item, crew_member: item.fullName, isSelected: false }]
				}));
				this.allDataTable = result;
				this.dataTable = this.allDataTable;
				for (let i = 0; i < this.dataTable.length; i++) {
					if (this.dataTable[i].status === 0) {
						this.isSubmittedStatus = true;
						break;
					} else {
						this.isSubmittedStatus = false;
					}
				}
				this.itemIsNotSubmitted = [];
				if (this.itemIsNotSubmitted.length === 0) {
					let strTitle = this.FormMSG(9, 'Success');
					this.$bvToast.toast(this.FormMSG(12, 'All not submitted for validation send'), {
						title: strTitle,
						variant: 'success',
						toaster: 'b-toaster-top-center',
						solid: true
					});
				}
				this.notSumbmittedItem = false;
			}
		},
		async setSubmitData() {
			let new_data = this.dataTable;
			for (let i = 0; i < this.itemIsNotSubmitted.length; i++) {
				new_data = new_data.map((item) => {
					if (item.status === this.itemIsNotSubmitted[0].status && item.isSelected === this.itemIsNotSubmitted[0].isSelected) {
						return { ...item, status: 1 };
					}
					return item;
				});
			}
			this.dataTable = new_data;
		},
		rowClicked(item) {
			this.update = true;
			this.showModal(item);
		},
		showModal(item) {
			this.editData = item;
			this.isActionCrud = this.updateData;
			this.Open = true;
		},
		hideModal() {
			this.Open = false;
		},
		closeModal() {
			this.Open = false;
			this.editData = {};
		},
		tsDetailLink(id) {
			return `/timesheetsEntryCrew/${id.toString()}?activeFilter=${this.groupByFilter}`;
		},
		showDetailTimesheet(item) {
			store.setCurTimesheet(item);
			const tsLink = this.tsDetailLink(item.id);
			this.$router.push({
				path: tsLink
			});
		},
		paginate(array, page_size, page_number) {
			if (array.length !== 0) return array.slice((page_number - 1) * page_size, page_number * page_size);
			return [];
		},
		handleClickPrevious() {
			this.positionNext -= 1;
			this.recordOffSet = this.positionNext;
			if (this.recordOffSet === 0) {
				this.disableButtonPrev = true;
			} else {
				this.disableButtonPrev = false;
			}
			let setDataFilter = {};
			this.isLoading = true;
			if (this.id === this.timecards_One) {
				if (this.groupByFilter === this.groupByPerson) {
					setDataFilter = {
						...this.setFilterName('personId', +this.Timesheets[0].tsDay.user.id),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByStatus) {
					setDataFilter = {
						...this.setFilterName('status', +this.Timesheets[0].status),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByDate) {
					setDataFilter = {
						...this.setFilterName('startDate', this.Timesheets[0].date),
						...this.setFilterName('endDate', this.Timesheets[0].date),
						activateCrewAccessRestriction: true
					};
				}
				this.persitendData = setDataFilter;
				this.getDataNext_one_cordon(setDataFilter);
			}
			if (this.id === this.timesheets_Two) {
				if (this.groupByFilter === this.groupByPerson) {
					setDataFilter = {
						...this.setFilterName('personId', +this.Timesheets[0].timesheet.user.id),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByStatus) {
					setDataFilter = {
						...this.setFilterName('status', +this.Timesheets[0].status),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByDate) {
					setDataFilter = {
						...this.setFilterName('startDate', this.Timesheets[0].startDate),
						...this.setFilterName('endDate', this.Timesheets[0].endDate),
						activateCrewAccessRestriction: true
					};
				}
				this.persitendData = setDataFilter;
				this.getDataNext_two_cordon(setDataFilter);
			}
		},
		handleClickNext() {
			this.positionNext += 1;
			this.recordOffSet = this.positionNext;
			let setDataFilter = {};
			this.isLoading = true;
			if (this.id === this.timecards_One) {
				if (this.groupByFilter === this.groupByPerson) {
					setDataFilter = {
						...this.setFilterName('personId', +this.Timesheets[0].tsDay.user.id),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByStatus) {
					setDataFilter = {
						...this.setFilterName('status', +this.Timesheets[0].status),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByDate) {
					setDataFilter = {
						...this.setFilterName('startDate', this.Timesheets[0].date),
						...this.setFilterName('endDate', this.Timesheets[0].date),
						activateCrewAccessRestriction: true
					};
				}
				this.persitendData = setDataFilter;
				this.getDataNext_one_cordon(setDataFilter);
			}
			if (this.id === this.timesheets_Two) {
				if (this.groupByFilter === this.groupByPerson) {
					setDataFilter = {
						...this.setFilterName('personId', +this.Timesheets[0].timesheet.user.id),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByStatus) {
					setDataFilter = {
						...this.setFilterName('status', +this.Timesheets[0].status),
						activateCrewAccessRestriction: true
					};
				}
				if (this.groupByFilter === this.groupByDate) {
					setDataFilter = {
						...this.setFilterName('startDate', this.Timesheets[0].startDate),
						...this.setFilterName('endDate', this.Timesheets[0].endDate),
						activateCrewAccessRestriction: true
					};
				}
				this.persitendData = setDataFilter;
				this.getDataNext_two_cordon(setDataFilter);
			}
		},
		async refreshDataItem(item) {
			let result = [...(await getProjectUserTsDays(this.persitendData))].map((item) => ({
				...item,
				crew_member: item.fullName,
				isSelected: false,
				data: [{ ...item, crew_member: item.fullName }]
			}));
			this.Fields = this.tsfields;
			this.allDataTable = result;
			this.pages = [...this.setFilterSize()].map((item) => {
				return { value: item, text: item };
			});
			this.dataTable = this.allDataTable.slice(0, this.setPage);
			this.totalPage = this.dataTable.length;
		},
		debutTotalPageNow() {
			let result = this.recordOffSet * this.setPage + this.setPage - this.setPage;
			return result;
		},
		totalPageNow() {
			let sum = this.recordOffSet * this.setPage + this.setPage;
			return sum < this.totalAllPageRecords ? sum : this.totalAllPageRecords;
		},
		handleRefreshData(result) {
			let data = result;
			this.dataTable = this.dataTable.map((item) => {
				if (+item.tsDay.user.id === +data.user.id && +item.tsDay.id === +data.id) {
					return { ...item, tsDay: { ...item.tsDay, ...data, user: { ...item.tsDay.user } } };
				}
				return item;
			});
		}
	}
};
</script>
